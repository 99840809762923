// MEDIA BREAKPOINTS
$mob: 767px;
$small-mob: 480px;
$tab: 1024px;
$small-lap: 1200px;
$lap: 1366px;
$custom-media: 1700px;

//SITE COLORS

$dark-font: #222;
$base-color: #E92C30;

