img {
  vertical-align: middle;
  display: block;
  width: 100%;
  height: auto
}

ul {
  list-style-type: none
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: 0
}

a {
  &:hover, &:focus, &:active {
    text-decoration: none;
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 20px 0;
  font-weight: 400;
  font-family: "Exo2";
}

input:invalid {
  box-shadow: none
}

input,
select,
textarea {
  border-radius: 0;
  box-shadow: none
}

body {
  font-size: 15px;
  line-height: 24px;
  font-family: OpenSans, Arial;
  background-color: #F2F4F7
}

.container {
  margin: 0 auto;
  max-width: 1620px;
  width: 100%
}

.btn {
  background-color: #E92C30;
  transition: all .3s ease;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  &:hover {
    opacity: .8;
    color: #fff;
  }
}

.title-big {
  font-family: "Exo2";
  font-weight: 400;
  font-size: 36px;
  color: #000;
  text-transform: uppercase;
  line-height: 41px;
  border-bottom: 2px solid #F45C70;
  padding-bottom: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
  margin-top: 0;
  @media (max-width: $custom-media) {
    font-size: 24px;
    line-height: 28px;
  }
}

.txt-title {
  font-size: 24px;
  display: block;
  margin: 15px 0;
  @media (max-width: $custom-media) {
    font-size: 20px;
    margin: 10px 0;
  }
}

.small-title, h4 {
  font-size: 20px;
  font-family: "OpenSans";
  font-weight: 600;
  margin: 12px 0;
  @media (max-width: $mob) {
    font-size: 14px;
  }
}

.order-form-title {
  font-size: 24px;
  font-family: "OpenSansBold";
  text-transform: uppercase;
  margin-bottom: 35px;
  @media (max-width: $custom-media) {
    font-size: 18px;
    margin-bottom: 20px;
  }
}