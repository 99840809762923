.price_page {
  .content_wrap {
    .full_width {
      .responsive-table-wrap {
        padding: 0 30px 45px;
        overflow: auto;
        @media (max-width: $custom-media) {
          padding-left: 15px;
          padding-right: 15px;
        }
        table {
          table-layout: fixed;
          width: 100%;
          min-width: 1150px;
          tr {
            th:first-of-type {
              width: 4%;
            }
            th:not(:first-of-type){
              width: 32%;
            }
            th, td:first-of-type {
              font-weight: 600;
              text-transform: uppercase;
              text-align: center;
            }
            th, td {
              padding: 20px;
              border: 1px solid #CED0D4;
              transition: all .3s ease;
              @media (max-width: $custom-media){
                padding: 20px 10px;
              }
              &:hover {
                background-color: #f1f1f1;
              }
            }
            td {
              p {
                font-weight: 600;
                font-size: 16px;
                text-transform: uppercase;
                margin-bottom: 10px;
                span {
                  color: $base-color;
                }
              }
              ul {
                li {
                  position: relative;
                  padding-left: 15px;
                  @media (max-width: $custom-media) {
                    font-size: 13px;
                  }
                  &:before {
                    display: block;
                    content: "";
                    width: 7px;
                    height: 7px;
                    background-color: #E92C30;
                    position: absolute;
                    left: 0;
                    top: 9px;
                  }
                }
              }
              a {
                display: block;
                color: #03F;
                transition: all .3s ease;
                padding-left: 15px;
                position: relative;
                @media (max-width: $custom-media) {
                  font-size: 13px;
                }
                &:before {
                  display: block;
                  content: "";
                  width: 7px;
                  height: 7px;
                  background-color: #E92C30;
                  position: absolute;
                  left: 0;
                  top: 9px;
                }
                &:hover {
                  color: $base-color
                }
              }
            }
          }
        }
      }
    }
  }
}

.price_page .content_wrap .right.doubled {
  margin-top: 0
}

.price_page.middle .content_wrap {
  border-bottom: 8px solid #F2F4F7
}

.price_page.middle .content_wrap .full_width {
  background-color: #fff;
  width: 100%
}

.price_page.middle .content_wrap .full_width .title {
  padding-right: 30px;
  padding-top: 30px
}

.price_page.middle .content_wrap .full_width .table_wrap {
  padding: 0 30px 45px
}

.price_page.middle .content_wrap .full_width .table {
  border: 1px solid #CED0D4;
  border-bottom: none;
  color: #000
}

.price_page.middle .content_wrap .full_width .table .heads {
  text-align: center
}

.price_page.middle .content_wrap .full_width .table .heads>div {
  padding: 20px 0;
  font-family: OpenSansBold;
  font-size: 16px;
  text-transform: uppercase
}

.price_page.middle .content_wrap .full_width .table .heads>div:not(:first-child) {
  font-size: 17px
}

.price_page.middle .content_wrap .full_width .table .text>div {
  padding: 15px 20px
}

.price_page.middle .content_wrap .full_width .table .text>div:not(:first-child) h4 {
  font-family: OpenSansBold;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 10px
}

.price_page.middle .content_wrap .full_width .table .text>div:not(:first-child) h4 span {
  color: #E92C30
}

.price_page.middle .content_wrap .full_width .table .text>div:not(:first-child) p {
  font-size: 14px;
  padding-left: 15px;
  position: relative;
  line-height: 22px
}

.price_page.middle .content_wrap .full_width .table .text>div:not(:first-child) p:before {
  display: block;
  content: "";
  width: 7px;
  height: 7px;
  background-color: #E92C30;
  position: absolute;
  left: 0;
  top: 8px
}

.price_page.middle .content_wrap .full_width .table .text>div:not(:first-child) p a {
  color: #03F;
  border-bottom: 2px solid transparent;
  -webkit-transition: all .4s ease;
  transition: all .4s ease
}

.price_page.middle .content_wrap .full_width .table .text>div:not(:first-child) p a:hover {
  color: #E92C30;
  border-color: #E92C30
}

.price_page.middle .content_wrap .full_width .table .total>div {
  padding: 20px 25px;
  font-size: 20px;
  text-transform: uppercase;
  font-family: OpenSansBold
}

.price_page.middle .content_wrap .full_width .table>div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #CED0D4
}

.price_page.middle .content_wrap .full_width .table>div>div {
  border-right: 1px solid #CED0D4;
  -webkit-transition: all .4s ease;
  transition: all .4s ease
}

.price_page.middle .content_wrap .full_width .table>div>div:hover {
  background-color: #F2F4F7
}

.price_page.middle .content_wrap .full_width .table>div>div:last-of-type {
  border-right: none
}

.price_page.middle .content_wrap .full_width .table>div>div:first-of-type {
  width: 4%;
  font-family: OpenSansBold;
  text-align: center;
  padding-left: 0;
  padding-right: 0
}

.price_page.middle .content_wrap .full_width .table>div>div:not(:first-of-type) {
  width: 32%
}

.price_page.middle .mobile_table {
  display: none;
  width: 290px;
  margin-left: auto;
  margin-right: auto;
  color: #000
}

.price_page.middle .mobile_table>div {
  border: 1px solid #ccc;
  margin-top: 10px
}

.price_page.middle .mobile_table>div .button {
  text-transform: uppercase;
  font-family: OpenSansBold;
  font-size: 14px;
  padding: 10px 15px;
  cursor: pointer;
  position: relative
}

.price_page.middle .mobile_table>div .button.active {
  background-color: rgba(0, 0, 0, .7);
  color: #fff
}

.price_page.middle .mobile_table>div .button.active:before {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  right: 20px;
  top: 14px
}

.price_page.middle .mobile_table>div .button:after {
  display: block;
  content: "";
  width: 30px;
  height: 30px;
  background-color: #E92C30;
  position: absolute;
  top: 7px;
  right: 10px;
  z-index: 50
}

.price_page.middle .mobile_table>div .button:before {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  position: absolute;
  right: 22px;
  top: 16px;
  z-index: 100;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

.price_page.middle .mobile_table>div .content {
  display: none
}

.price_page.middle .mobile_table>div .content h4 {
  font-family: OpenSansBold;
  font-size: 12px;
  text-transform: uppercase;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 15px 10px 45px;
  position: relative
}

.price_page.middle .mobile_table>div .content h4:after {
  display: block;
  content: "";
  width: 25px;
  height: 25px;
  background-color: #F45C70;
  position: absolute;
  top: 9px;
  left: 15px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-family: OpenSansBold
}

.price_page.middle .mobile_table>div .content .links p a,
.price_page.middle .mobile_table>div .content h4 span {
  color: #E92C30
}

.price_page.middle {
  .mobile_table{
    >div {
      .content {
        counter-reset: section;
        h4 {
          min-height: auto;
          &:after {
            counter-increment: section;
            content: counter(section);
          }
        }
        .text {
          padding: 0;
          p {
            position: relative;
            padding-left: 25px;
            padding-right: 10px;
            padding-top: 0px;
            padding-bottom: 0px;
            &:before {
              display: block;
              content: "";
              width: 7px;
              height: 7px;
              background-color: #E92C30;
              position: absolute;
              left: 10px;
              top: 9px;
            }
          }
        }
      }
    }
  }
}

.price_page.middle .mobile_table>div .content .bottom .links {
  padding: 10px 15px
}

.price_page.middle .mobile_table>div .content .links p a:hover {
  text-decoration: underline
}

.price_page.middle .mobile_table>div .content p {
  font-size: 12px
}

.price_page.middle .mobile_table>div .content .total {
  padding-bottom: 10px;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  font-family: OpenSansBold;
  border-top: 1px solid #ccc;
  padding-top: 10px
}

.price_page.middle .mobile_table>div .bottom h4:after {
  display: none
}

.price_page.middle .mobile_table>div .bottom h4 {
  padding-left: 15px
}