/* LIBS */
@import "assets/helper/bootstrap";
@import "assets/libs/owl-carousel";
@import "assets/libs/nprogress";
@import "assets/libs/fancybox3";
/* ASSETS */
@import "assets/vars";
@import "assets/fonts";
@import "assets/base";
/* MODULES */
@import "assets/modules/header";
@import "assets/modules/global/global";
@import "assets/modules/pages";
@import "assets/modules/footer";

@font-face {
  font-family: RobotoRegular;
  src: url(../fonts/Roboto/Roboto-Regular.eot);
  src: url(../fonts/Roboto/Roboto-Regular.eot?#iefix) format("embedded-opentype"), url(../fonts/Roboto/Roboto-Regular.woff) format("woff"), url(../fonts/Roboto/Roboto-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: RobotoBold;
  src: url(../fonts/Roboto/Roboto-Bold.eot);
  src: url(../fonts/Roboto/Roboto-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/Roboto/Roboto-Bold.woff) format("woff"), url(../fonts/Roboto/Roboto-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: OpenSansBold;
  src: url(../fonts/OpenSans/OpenSans-Bold.eot);
  src: url(../fonts/OpenSans/OpenSans-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/OpenSans/OpenSans-Bold.woff) format("woff"), url(../fonts/OpenSans/OpenSans-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: OpenSansItalic;
  src: url(../fonts/OpenSans/OpenSans-Italic.eot);
  src: url(../fonts/OpenSans/OpenSans-Italic.eot?#iefix) format("embedded-opentype"), url(../fonts/OpenSans/OpenSans-Italic.woff) format("woff"), url(../fonts/OpenSans/OpenSans-Italic.ttf) format("truetype");
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: OpenSans;
  src: url(../fonts/OpenSans/OpenSans.eot);
  src: url(../fonts/OpenSans/OpenSans.eot?#iefix) format("embedded-opentype"), url(../fonts/OpenSans/OpenSans.woff) format("woff"), url(../fonts/OpenSans/OpenSans.ttf) format("truetype");
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: Exo2Regular;
  src: url(../fonts/Exo2/Exo2-0-Regular.eot);
  src: url(../fonts/Exo2/Exo2-0-Regular.eot?#iefix) format("embedded-opentype"), url(../fonts/Exo2/Exo2-0-Regular.woff) format("woff"), url(../fonts/Exo2/Exo2-0-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: Exo2Light;
  src: url(../fonts/Exo2/Exo2-Light.eot);
  src: url(../fonts/Exo2/Exo2-Light.eot?#iefix) format("embedded-opentype"), url(../fonts/Exo2/Exo2-Light.woff) format("woff"), url(../fonts/Exo2/Exo2-Light.ttf) format("truetype");
  font-style: normal;
  font-weight: 400
}

footer,
header,
section {
  position: relative
}

#menu,
#menu_overlay {
  position: fixed;
  height: 100%
}

#menu_overlay {
  left: 0;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, .71);
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 1.1s ease;
  transition: all 1.1s ease
}

#menu {
  left: -310px;
  top: 0;
  width: 300px;
  background-color: #fff;
  z-index: 3000;
  border-right: 1px solid #F4F4F4;
  visibility: visible;
  -webkit-transition: all 1.1s ease;
  transition: all 1.1s ease
}

#menu .third .close {
  position: absolute;
  width: 90%;
  left: 5%;
  bottom: 20px
}

#menu .close {
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 18px;
  color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  border: 1px solid #EEE;
  background-image: url(../img/ics/close.png);
  background-position: left 30px center;
  background-repeat: no-repeat;
  margin-top: 15px
}

#menu .close:hover {
  border-color: #F45C70
}

#menu .top {
  padding: 15px 15px 20px
}

#menu .top .logo {
  position: relative
}

#menu .top .logo:hover {
  opacity: .7
}

#menu .top .logo a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%
}

#menu .parent li:after,
#menu .parent > li.with_child a:after {
  width: 6px;
  height: 6px;
  z-index: 100;
  top: 17px;
  content: ""
}

#menu .left_side {
  left: 599px
}

#menu .parent {
  margin-top: 40px;
  @media (max-width: $custom-media) {
    margin-top: 15px;
  }
}

#menu .parent > li {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 1px
}

#menu .parent > li.with_child {
  position: static !important
}

#menu .parent > li.with_child:hover > .children {
  display: block
}

#menu .parent > li.with_child:hover > a:after {
  border-color: #fff
}

#menu .parent > li.with_child:after {
  display: none
}

#menu .parent > li.with_child a {
  position: relative
}

#menu .parent > li.with_child a:after {
  display: block;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  position: absolute;
  right: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

#menu .parent > li.with_child a:hover:after {
  border-color: #fff
}

#menu .parent > li:hover {
  background-color: #D62D32
}

#menu .parent > li:hover > a {
  color: #fff
}

#menu .parent > li > a {
  padding: 8px 15px;
  box-shadow: 0 1px 0 #F6F6F6
}

#menu .parent li {
  position: relative
}

#menu .parent li:hover > .children {
  display: block
}

#menu .parent li.with_child:after {
  display: none;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  position: absolute;
  right: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);

}

#menu .parent li:hover:after {
  border-color: #fff
}

#menu .parent li a {
  display: block;
  font-size: 14px;
  color: #000
}

#menu .parent .children_first {
  width: 300px;
  background-color: #fff;
  z-index: 3000;
  position: absolute;
  right: -300px;
  height: 100%;
  top: 0;
  padding-top: 170px;
  border-left: 1px solid #F4F4F4;
  display: none;
  @media (max-width: $tab) {
    padding-top: 10px;
  }
}

#menu .parent .children_first li.with_child:hover > a,
#menu .parent .children_first ul li a:hover {
  background-color: #D62D32;
  color: #fff;
  font-family: OpenSansBold
}

#menu .parent .children_first li.with_third_inner:hover > .children {
  display: none
}

#menu .parent .children_first li.with_child {
  position: static
}

#menu .parent .children_first li.with_child:hover > a:after {
  border-color: #fff
}

#menu .parent .children_first li.with_child > a {
  position: relative
}

#menu .parent .children_first li.with_child > a:after {
  display: block;
  content: "";
  width: 6px;
  height: 6px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  position: absolute;
  right: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 100;
  top: 10px
}

#menu .parent .children_first ul li a:after,
#menu .parent .children_first ul li.with_third_inner:after,
#menu .parent .children_first > li:after {
  display: none
}

#menu .parent .children_first.secondary {
  right: -600px
}

#menu .parent .children_first ul {
  margin-top: 25px
}

#menu .parent .children_first ul li:first-of-type a {
  font-family: OpenSansBold;
  font-size: 16px;
  padding-left: 18px
}

#menu .parent .children_first ul li:after {
  top: 10px
}

#menu .parent .children_first ul li a {
  padding-left: 28px;
  padding-top: 8px;
  padding-bottom: 8px;
}

#menu .parent .children_first.third {
  margin-top: 0
}

#menu .parent .children_first.third li:not(:first-of-type) a {
  font-family: OpenSans;
  font-size: 14px;
  padding-left: 28px
}

.alert {
  padding: 10px;
  margin-bottom: 10px;
  font-size: 13px
}

.alert-danger {
  border: 2px solid red;
  color: red
}

.alert-success {
  border: 2px solid green;
  color: green
}

.admin,
.admin a {
  color: #fff
}

.admin {
  display: inline-block;
  background-color: #E92C30;
  font-size: 18px;
  font-family: OpenSansBold;
  text-transform: uppercase;
  width: 100%;
  padding: 15px 0;
  -webkit-transition: all .5s linear;
  transition: all .5s linear;
  text-align: center;
  margin-bottom: 20px;
  z-index: 10000000
}

.third {
  min-height: 513px
}

@media only screen and (min-width: 1199px) {
  .children_menu_last {
    max-height: 370px;
    overflow-y: scroll
  }
}

.content_wrap .left .content .text ul {
  list-style-image: url(/img/listred.png);
  padding-left: 20px
}

.content_wrap .left .content .text ol {
  padding-left: 20px;
}

.content_wrap .left .content .text img {
  max-width: 100% !important;
  max-height: auto !important;
  height: auto !important
}

@media only screen and (max-width: 1700px) {
  .container {
    max-width: 1200px
  }
  #hidden-form {
    max-width: 600px
  }
  #hidden-form h2,
  #hidden-form .title {
    font-size: 16px;
    padding: 15px
  }
  #hidden-form form {
    padding: 15px
  }
  #hidden-form form > div input {
    font-size: 12px;
    margin-bottom: 7px
  }
  #hidden-form form .flex_wrap .inputs {
    padding-left: 20px
  }
  #hidden-form form > div label {
    font-size: 12px
  }
  #hidden-form form button {
    font-size: 12px;
    margin-top: 10px
  }
  #menu .parent .children_first ul {
    margin-top: 15px
  }
  header .top {
    padding: 0 7px 0 8px;
    max-width: 1200px;
    margin: 0 auto
  }
  header {
    left: 0;
    padding: 0
  }
  header .top .nav_wrap {
    padding: 10px 15px;
    font-size: 12px
  }
  header .top .nav_wrap #menu_btn {
    height: 55px;
    padding-top: 30px;
    width: 50px;
    left: 25px;
    top: 25px;
    position: fixed;
    font-size: 12px;
    background-position: center top 9px;
    bottom: auto;
    -webkit-background-size: 24px;
    background-size: 24px;
    background-color: $base-color;
    @media (max-width: $lap) {
      left: 5px;
      right: auto;
      top: 25px;
    }
    @media (max-width: 1300px) {
      position: absolute;
      left: 0px;
      bottom: -55px;
      top: auto;
    }
    @media (max-width: $mob) {
      position: static;
      width: 100%;
      transform: rotate(0);
      background-image: none;
      font-size: 14px;
      margin-bottom: 20px;
      line-height: 50px;
      text-transform: uppercase;
      padding: 0;
    }
  }
  header .top .logo {
    width: 21%;
    align-self: center
  }
  header .top .work_shedule {
    width: 11.5%
  }
  header .top .work_shedule > div {
    padding: 8px
  }
  header .top .work_shedule p {
    padding-left: 8px;
    margin-bottom: 10px
  }
  header .top .nav_info ul li {
    margin-right: 15px
  }
  header .top .nav_info {
    width: 26%
  }
  header .top .nav_info ul {
    margin-bottom: 18px
  }
  header .top .contact_numbers_and_button > div {
    margin-bottom: 10px
  }
  header .top .contact_numbers_and_button > div span {
    font-size: 12px
  }
  header .top .contact_numbers_and_button .order_consult {
    font-size: 13px;
    padding: 10px 0
  }
  header .top .contact_numbers_and_button {
    width: 30%
  }
  header .top .language {
    width: 5%
  }
  #site_carousel .item .text {
    top: 181px;
    left: 80px
  }
  #site_carousel .item .text h4 {
    font-size: 24px;
    line-height: 30px
  }
  #site_carousel .item .text p {
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px
  }
  #site_carousel .item .text {
    padding: 10px 15px;
  }
  #site_carousel .item img {
    min-height: 500px
  }
  #site_carousel .owl-controls .owl-nav > div.owl-prev {
    left: 20px
  }
  #site_carousel .owl-controls .owl-nav > div.owl-next {
    right: 20px
  }
  .typical_page_breadcrumbs {
    margin-top: 143px
  }
  #breadcrumbs {
    padding-top: 4px;
    padding-bottom: 4px
  }
  #breadcrumbs .links a,
  #breadcrumbs .links span {
    font-size: 10px;
    padding-top: 1px;
    margin-right: 22px
  }
  #breadcrumbs .links a:before,
  #breadcrumbs .links span:before {
    width: 15px;
    height: 15px;
    top: 5px;
    left: -21px
  }
  #breadcrumbs .links a:after,
  #breadcrumbs .links span:after {
    width: 5px;
    height: 5px;
    top: 10px;
    left: -17px
  }
  .page_content .content_wrap .left .consult_nums {
    padding: 10px 70px 10px 50px;
    -webkit-background-size: 24px;
    background-size: 24px;
    background-position: left 16px center
  }
  .page_content .content_wrap .left .consult_nums p:first-of-type {
    font-size: 14px
  }
  .page_content .content_wrap .left .consult_nums p:nth-of-type(2) {
    font-size: 16px;
    margin-top: 0
  }
  .page_content .content_wrap .left .welcome_title {
    padding-right: 15px
  }
  .page_content .content_wrap .left .welcome_title .descr {
    align-items: center
  }
  .page_content .content_wrap .left .welcome_title .descr .text {
    width: 100%;
    font-size: 12px
  }
  .page_content .content_wrap .left .welcome_title .descr .bottom_text {
    font-size: 12px;
    padding-left: 20px
  }
  .page_content .content_wrap .left .welcome_title .descr .bottom_text .mission {
    font-size: 12px;
    padding: 6px 0;
    width: 200px
  }
  .page_content h3,
  .page_content .header {
    padding-left: 15px;
  }
  .page_content .content_wrap .left .direction .title,
  .page_content .content_wrap .left .offers {
    padding-right: 15px
  }
  .page_content h3,
  .page_content .header {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px
  }
  .page_content .content_wrap .left .offers .items > div:before {
    width: 25px;
    height: 25px
  }
  .page_content .content_wrap .left .offers .items > div:after {
    top: -15px;
    left: 16px
  }
  .page_content .content_wrap .right .last_projects .items .item {
    padding-left: 15px
  }
  .page_content .content_wrap .left .offers .items > div p {
    font-size: 12px
  }
  .page_content .content_wrap .left .direction .items > div {
    min-height: 160px
  }
  .page_content .content_wrap .left .direction .items > div img {
    max-height: 160px
  }
  .page_content .content_wrap .left .direction .items > div .text {
    top: 21px
  }
  .page_content .content_wrap .left .direction .items > div .text h4,
  .page_content .content_wrap .left .direction .items > div .text .subheader {
    font-size: 24px
  }
  .page_content .service_form.static_form form .services_select > div {
    min-height: 42px
  }
  .page_content .service_form.static_form form .services_select > div .items {
    top: 42px
  }
  .page_content .service_form.static_form form .services_select > div .items > div label {
    font-size: 12px
  }
  .page_content .service_form.static_form form .services_select > div span {
    font-size: 12px;
    line-height: 43px
  }
  .page_content .content_wrap .left .page_bottom_text p {
    font-size: 12px;
    line-height: 20px
  }
  .page_content .content_wrap .right .last_projects .items .item .title {
    font-size: 12px;
    line-height: 19px;
    width: 154px;
    left: 25px
  }
  .page_content .content_wrap .right .last_projects .go_to_portfolio a {
    font-size: 12px
  }
  .page_content .content_wrap .right .last_projects .items .item .date {
    font-size: 12px;
    padding: 4px 10px
  }
  .page_content .content_wrap .right .news_and_events {
    padding-top: 20px;
  }
  .page_content .content_wrap .right .title {
    padding-left: 15px
  }
  .page_content .right .download_links a {
    -webkit-background-size: 30px;
    background-size: 30px;
    padding-left: 40px;
    font-size: 12px !important;
    line-height: 20px;
    margin-top: 3px;
    padding-top: 10px;
    padding-bottom: 10px
  }
  .page_content .content_wrap .right .news_and_events .items > div {
    padding-left: 15px;
    padding-right: 15px
  }
  .page_content .content_wrap .right .news_and_events .items > div .text {
    width: 67%;
    order: 0;
    padding-right: 20px
  }
  .page_content .content_wrap .right .news_and_events .items > div .text .title a {
    font-size: 12px;
    line-height: 10px
  }
  .page_content .content_wrap .right .news_and_events .items > div .text p {
    line-height: 15px
  }
  .page_content .content_wrap .right .news_and_events .items > div .text p {
    margin-top: 10px;
    font-size: 12px;
    line-height: 15px
  }
  .page_content .content_wrap .right .news_and_events .items > div .bottom {
    padding-right: 0;
    order: 4;
    margin-top: 12px;
    font-size: 12px
  }
  .page_content .content_wrap .right .news_and_events .items > div .bottom span {
    font-size: 12px
  }
  .page_content .content_wrap .right .news_and_events .items > div .img {
    width: 32%;
    text-align: right;
    margin-bottom: 10px;
    align-self: center
  }
  .page_content .content_wrap .right .news_and_events .items > div .img img {
    max-width: 160px;
    display: inline-block
  }
  .page_content .content_wrap .right .news_and_events .read_all a {
    font-size: 12px
  }
  .page_content .content_wrap .right .subscribe_form {
    padding-left: 10px;
    padding-right: 10px
  }
  .page_content .content_wrap .right .subscribe_form > div label {
    padding-left: 10px;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 10px
  }
  .page_content .content_wrap .right .subscribe_form > div input {
    font-size: 12px;
    padding-left: 10px
  }
  .page_content .content_wrap .right .subscribe_form > div button {
    font-size: 14px;
    width: 100px
  }
  .page_content .content_wrap .right .reviews {
    padding-top: 20px
  }
  .page_content .content_wrap .right .reviews .items > div .name {
    font-size: 14px
  }
  .page_content .content_wrap .right .reviews .items > div p {
    font-size: 12px;
    line-height: 20px
  }
  .page_content .content_wrap .right .reviews .items > div {
    padding: 18px 15px
  }
  .page_content .content_wrap .right .reviews .items > div span {
    margin-bottom: 10px;
    font-size: 12px
  }
  .page_content .content_wrap .right .reviews .items > div a {
    margin-top: 10px;
    font-size: 12px
  }
  .page_content .content_wrap .right .reviews .all_reviews a {
    font-size: 12px
  }
  .page_content .content_wrap .right .clients {
    padding-top: 20px
  }
  .page_content .content_wrap .right .clients .items > div {
    width: 50%;
    text-align: center
  }
  .page_content .content_wrap .right .clients .items > div img {
    max-width: 190px;
    display: inline-block
  }
  .page_content .content_wrap .right .clients .all_clients a {
    font-size: 12px
  }
  .page_content .right .order_consult form .radio_wrap,
  footer .items_wrap .first .count_and_soc > div:first-child {
    margin-bottom: 10px
  }
  .page_content .right .order_consult form .radio_wrap label:before {
    width: 15px;
    height: 15px;
    top: 4px
  }
  .page_content .right .order_consult form .radio_wrap label:after {
    width: 9px;
    height: 9px;
    top: 7px
  }
  .page_content .right .order_consult form .radio_wrap label {
    padding-left: 23px
  }
  footer .items_wrap {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 14px
  }
  footer .items_wrap .first {
    width: 31%
  }
  footer .items_wrap .first > p {
    font-size: 12px
  }
  footer .items_wrap .first .count_and_soc {
    margin-top: 12px;
    align-items: flex-start
  }
  footer .items_wrap .work_shedule {
    width: 11.7%
  }
  footer .items_wrap .contact_numbers_and_button,
  footer .items_wrap .nav_info {
    width: 27%
  }
  footer .items_wrap .nav_info ul li {
    margin-right: 20px;
    font-size: 12px
  }
  footer .items_wrap .nav_info ul li a {
    font-size: 12px
  }
  footer .items_wrap .nav_info p {
    font-size: 12px;
    line-height: 20px
  }
  footer .items_wrap .work_shedule > div {
    padding-left: 8px;
    padding-right: 8px
  }
  footer .items_wrap .work_shedule p {
    margin-bottom: 10px;
    font-size: 12px
  }
  footer .items_wrap .work_shedule > div span {
    font-size: 12px;
    line-height: 20px
  }
  footer .items_wrap .nav_info ul {
    margin-bottom: 20px
  }
  .page_content .news .select_cat,
  footer .items_wrap .contact_numbers_and_button > div {
    margin-bottom: 10px
  }
  footer .items_wrap .contact_numbers_and_button > div span {
    font-size: 12px;
    line-height: 20px
  }
  footer .items_wrap .contact_numbers_and_button .order_consult {
    font-size: 12px;
    padding: 8px 0
  }
  .page_content .right .back_link {
    font-size: 14px;
    background-position: left 15px center;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 46px
  }
  .page_content .our_team .title {
    padding-right: 15px
  }
  .page_content .our_team .items > div p {
    font-size: 12px
  }
  .page_content .right .download_links {
    padding: 15px 15px 20px
  }
  .page_content .right .order_consult form {
    margin-top: 10px
  }
  .page_content .right .order_consult form input {
    font-size: 12px;
    padding-left: 35px;

  }
  .page_content .right .order_consult form div label {
    font-size: 12px;
    left: 35px;
  }
  .page_content .right .order_consult form button {
    font-size: 12px;
    padding: 9px 0
  }
  .page_content .right .banner {
    padding: 15px
  }
  .page_content .news .title {
    padding-right: 15px
  }
  .page_content .news .select_cat form select {
    font-size: 14px
  }
  .page_content .news .items .item .text {
    padding-left: 20px;
    padding-top: 0;
    font-size: 12px;
    padding-right: 15px;
    width: 70%
  }
  .page_content .news .items .item .text .item_text_bottom span {
    font-size: 12px
  }
  .page_content .news .items .item .img {
    width: 30%
  }
  .pagination .links a,
  .pagination .links span {
    font-size: 12px
  }
  .pagination .links .next {
    margin-left: 10px
  }
  .pagination .links .prev {
    margin-right: 10px
  }
  .pagination {
    padding: 20px 0
  }
  .news_page .left .subscribe_form > div label {
    font-size: 12px;
    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 10px
  }
  .news_page .left .subscribe_form {
    padding-left: 10px;
    padding-right: 10px
  }
  .news_page .left .subscribe_form > div input {
    font-size: 12px;
    padding: 0 10px
  }
  .map_page .title,
  .reviews_page .title {
    padding-right: 15px
  }
  .news_page .left .subscribe_form > div button {
    font-size: 14px;
    width: 100px
  }
  .reviews_page .items .item {
    padding-bottom: 20px
  }
  .reviews_page .items .item .img {
    width: 30%
  }
  .reviews_page .items .item .text {
    width: 70%;
    padding-left: 20px;
    padding-top: 0;
    font-size: 12px
  }
  .map_page .contacts_info .text {
    font-size: 12px
  }
  .map_page .contacts_info .text .back_to_contacts {
    margin-top: 10px;
    font-size: 12px
  }
  #map {
    min-height: 400px
  }
  .map_page .message_form form .top > div:not(:last-of-type) {
    width: 50%
  }
  .map_page .message_form form .top > div:first-of-type {
    padding-right: 7.5px
  }
  .map_page .message_form form .top > div:nth-of-type(2) {
    padding-left: 7.5px
  }
  .map_page .message_form form .top > div input {
    font-size: 12px;
    height: 42px
  }
  .map_page .message_form form .top > div label {
    font-size: 12px;
    top: 10px
  }
  .map_page .message_form form textarea {
    font-size: 12px
  }
  .map_page .message_form form .top > div:last-of-type {
    width: 100%;
    margin-top: 15px
  }
  .map_page .message_form form button {
    font-size: 14px;
    padding: 8px 0;
    width: 300px
  }
  .map_page .message_form form,
  .page_content .service_form form,
  .page_content .service_form .title {
    padding-left: 15px;
    padding-right: 15px
  }
  .map_page .message_form h4 {
    padding-left: 15px;
    font-size: 14px
  }
  .page_content .service_form .title {
    font-size: 14px!important;
  }
  .page_content .service_form form {
    padding-bottom: 20px
  }
  .page_content .service_form form .top > div input {
    height: 42px;
    font-size: 12px
  }
  .page_content .service_form form .adress input,
  .page_content .service_form form .services_select select {
    height: 42px;
    font-size: 12px
  }
  .page_content .service_form form .services_select:after {
    top: 16px
  }
  .page_content .service_form form .bottom div .add_new_input,
  .page_content .service_form form .bottom div label {
    height: 42px;
    font-size: 12px;
    line-height: 36px
  }
  .page_content .service_form form .bottom div.add_new_input:before {
    top: 17px
  }
  .page_content .service_form form .bottom div.add_new_input:after {
    top: 13px
  }
  .page_content .service_form .bottom_btn.with_checkbox button {
    font-size: 12px;
    width: 350px;
    padding: 8px 0;
  }
  .content_wrap .left .content .text,
  .price_page.middle .content_wrap .full_width .title,
  .typical_page .content_wrap .left .typical_post .title,
  .typical_page .why_change_us .title {
    padding-right: 15px;
  }
  .page_content .service_form .bottom_btn.with_checkbox .checkbox {
    left: 0;
    top: 22px
  }
  .schema_page .schema .content .items.last > div:first-of-type:after,
  .schema_page .schema .content .items.last > div:first-of-type:before,
  .schema_page .schema .content .items.last > div:last-of-type:after,
  .schema_page .schema .content .items.last > div:last-of-type:before {
    display: none
  }
  .page_content .service_form .bottom_btn.with_checkbox .checkbox label:before {
    top: 1px
  }
  .content_wrap .left .content .text {
    padding-left: 15px
  }
  .typical_page .content_wrap .left .typical_post .content .text h4 {
    font-size: 14px
  }
  .typical_page .content_wrap .left .typical_post .content .text p {
    font-size: 12px;
    line-height: 20px
  }
  .typical_page .content_wrap .left .typical_post .content .text .columns {
    padding-bottom: 10px
  }
  .typical_page .why_change_us .links a {
    font-size: 12px;
    width: 100%;
    margin: 10px auto !important
  }
  .price_page.middle .content_wrap .full_width .table .heads > div:not(:first-child),
  .price_page.middle .content_wrap .full_width .table .text > div:not(:first-child) h4 {
    font-size: 14px
  }
  .price_page.middle .content_wrap .full_width .table_wrap {
    padding-left: 15px;
    padding-right: 15px
  }
  .price_page.middle .content_wrap .full_width .table .heads > div {
    font-size: 14px;
    padding: 15px 0
  }
  .price_page.middle .content_wrap .full_width .table .text > div:not(:first-child) p {
    font-size: 12px
  }
  .price_page.middle .content_wrap .full_width .table .total > div {
    font-size: 14px
  }
  .portfolio_page .content_wrap .portfolio .title {
    padding-right: 15px
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav {
    padding-left: 15px;
    padding-right: 15px
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a:after {
    right: 17px;
    top: 12px
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav .child li a {
    padding: 7px 10px
  }
  .portfolio_page .content_wrap .portfolio .text_block {
    padding: 10px 15px 20px
  }
  .portfolio_page .content_wrap .portfolio .text_block > div h4 {
    font-size: 14px
  }
  .portfolio_page .content_wrap .portfolio .text_block > div p,
  .portfolio_page .content_wrap .portfolio .text_block > div:last-of-type .client_review span {
    font-size: 12px
  }
  .portfolio_page .content_wrap .portfolio .text_block > div:first-of-type p {
    margin-bottom: 10px
  }
  .portfolio_page .content_wrap .portfolio .text_block > div:first-of-type p:nth-of-type(2) {
    margin-bottom: 20px
  }
  .portfolio_page .content_wrap .portfolio .text_block > div:nth-child(2) p {
    margin-bottom: 10px
  }
  .portfolio_page .content_wrap .portfolio .text_block > div:last-of-type h4 {
    margin-bottom: 12px
  }
  .portfolio_page .content_wrap .portfolio .text_block > div:last-of-type .client_review {
    margin-top: 12px;
    padding-top: 12px
  }
  .portfolio_page .content_wrap .portfolio .text_block > div:last-of-type .client_review a {
    margin-top: 10px;
    font-size: 12px
  }
  #portfolio_overlay .order {
    padding: 10px 15px
  }
  #portfolio_overlay .form {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto
  }
  #portfolio_overlay .order > div:first-of-type {
    padding-left: 25px
  }
  #portfolio_overlay .order > div:first-of-type span {
    font-size: 14px
  }
  #portfolio_overlay .order > div:last-of-type a {
    font-size: 12px;
    padding: 7px 0
  }
  #portfolio_overlay .img .owl-controls .owl-nav > div {
    -webkit-background-size: 25px;
    background-size: 25px
  }
  #portfolio_overlay .img .owl-controls .owl-nav > div.owl-prev {
    left: -60px
  }
  #portfolio_overlay .img .owl-controls .owl-nav > div.owl-next {
    right: -60px
  }
  #portfolio_overlay .service_form h4 {
    padding-top: 20px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 14px
  }
  #portfolio_overlay .service_form form {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px
  }
  #portfolio_overlay .service_form form .top > div input {
    height: 30px;
    font-size: 12px
  }
  #portfolio_overlay .service_form form .adress label,
  #portfolio_overlay .service_form form .top > div label {
    font-size: 12px;
    top: 3px
  }
  #portfolio_overlay .service_form form .adress input,
  #portfolio_overlay .service_form form .services_select select {
    height: 30px;
    font-size: 12px
  }
  #portfolio_overlay .service_form form .services_select:after {
    top: 10px
  }
  #portfolio_overlay .service_form form .bottom div .add_file_label,
  #portfolio_overlay .service_form form .bottom div.add_new_input {
    height: 30px;
    font-size: 12px;
    line-height: 26px
  }
  #portfolio_overlay .service_form form .bottom div.add_new_input:before {
    top: 12px
  }
  #portfolio_overlay .service_form form .bottom div.add_new_input:after {
    top: 8px
  }
  #portfolio_overlay .service_form .bottom_btn.with_checkbox > p {
    font-size: 12px;
    margin-top: 5px
  }
  #portfolio_overlay .service_form .bottom_btn.with_checkbox button {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    max-width: 290px;
    margin-top: 5px
  }
  #portfolio_overlay .service_form .bottom_btn.with_checkbox .checkbox {
    top: 20px
  }
  #portfolio_overlay .service_form .bottom_btn.with_checkbox .checkbox label {
    font-size: 12px
  }
  #portfolio_overlay .service_form form .services_select > div {
    min-height: 30px
  }
  #portfolio_overlay .service_form form .services_select > div a {
    font-size: 12px;
    line-height: 30px
  }
  #portfolio_overlay .service_form form .services_select > div .items.active {
    top: 30px
  }
  #portfolio_overlay .service_form form .services_select > div .items > div label {
    font-size: 12px
  }
  #portfolio_overlay .service_form form .services_select > div span {
    font-size: 12px;
    height: 30px;
    line-height: 30px
  }
  .page_content .reviews_form h4,
  .page_content .reviews_form .title {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px
  }
  .page_content .reviews_form form {
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px
  }
  .page_content.reviews_page_content .content_wrap .left .reviews_form .top .left,
  .page_content.reviews_page_content .content_wrap .left .reviews_form .top .right_block {
    width: 50%
  }
  .page_content .reviews_form form .top > .left > div input {
    height: 42px
  }
  .page_content .reviews_form form .top .left > div label {
    font-size: 12px !important;
    top: 10px;
  }
  .page_content.reviews_page_content .content_wrap .left .reviews_form .what_realised input {
    height: 42px;
  }
  .page_content.reviews_page_content .content_wrap .left .reviews_form .what_realised label {
    font-size: 12px;
    top: 10px
  }
  .page_content.reviews_page_content .content_wrap .left .reviews_form .message_area textarea {
    font-size: 12px;
    padding-top: 8px
  }
  .page_content .reviews_form form .bottom div .add_file_label {
    font-size: 12px;
    line-height: 37px;
    height: 42px
  }
  .page_content .reviews_form form .bottom div .add_new_input {
    font-size: 12px;
    height: 42px;
    line-height: 37px
  }
  .page_content .reviews_form form .bottom div.add_new_input:before {
    top: 18px
  }
  .page_content .reviews_form form .bottom div.add_new_input:after {
    top: 14px
  }
  .page_content .reviews_form .bottom_btn.with_checkbox > p {
    font-size: 12px
  }
  .page_content .reviews_form .bottom_btn.with_checkbox button {
    width: 350px;
    font-size: 12px;
    padding: 8px 0
  }
  .schema_page .schema .content > p {
    font-size: 12px;
    line-height: 20px
  }
  .schema_page .schema {
    padding-right: 15px
  }
  .schema_page .schema .content {
    padding-left: 15px;
    padding-bottom: 30px
  }
  .schema_page .schema .content .items > div .left {
    overflow: hidden;
    max-height: 92px
  }
  .schema_page .schema .content .first > div .left {
    max-height: 93px
  }
  .schema_page .schema .content .last > div .left {
    max-height: none
  }
  .schema_page .schema .content .items > div .left img {
    max-width: 114px
  }
  .schema_page .schema .content .items > div .right {
    padding-left: 114px;
    min-height: 92px
  }
  .schema_page .schema .content .middle > div .right {
    min-height: 92px
  }
  .schema_page .schema .content .items > div .left:after {
    left: 114px
  }
  .schema_page .schema .content .items > div .right .text,
  .schema_page .schema .content .items > div .right .text h4,
  .schema_page .schema .content .items > div .right .text h6 {
    font-size: 10px
  }
  .schema_page .schema .content .items > div:after {
    top: 47px
  }
  .schema_page .schema .content .items > div:before {
    top: 43px
  }
  .schema_page .schema .step {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 80px;
    background-position: left 30px center
  }
  .schema_page .schema .content .items.middle > div:after {
    right: 122px
  }
  .schema_page .schema .content .items.middle > div:before {
    right: 118px
  }
  .schema_page .schema .step.last {
    background-position: left 25px center
  }
  .schema_page .schema .content .items.last > div .right {
    min-height: 119px
  }
  .schema_page .schema .content .items.last > div:nth-of-type(2):after {
    right: 122px
  }
  .schema_page .schema .content .items.last > div:nth-of-type(2):before {
    right: 118px
  }
}

@media only screen and (max-width: 1310px) {
  .typical_page_breadcrumbs {
    margin-top: 193px
  }
}

@media only screen and (max-width: 1200px) {
  #menu .parent .children_first.third,
  #menu .parent > li.with_child > .children {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    padding-top: 10px;
    padding-bottom: 10px
  }
  #menu {
    overflow: scroll
  }
  #menu .third .close {
    display: none
  }
  #menu .parent > li.with_child > .children {
    margin-left: -21px;
    background-color: #f1f1f1
  }
  #menu .parent .children_first li.with_child:hover > a:after,
  #menu .parent > li.with_child:hover > a:after {
    transform: rotate(135deg)
  }
  #menu .parent .children_first.first:before,
  #menu .parent .children_first.third:before {
    display: block;
    content: "";
    width: 0;
    height: 0%;
    border-top: 10px solid #D62D32;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 0;
    left: 145px
  }
  header {
    position: static !important;
    background-color: #fff !important
  }
  .container {
    max-width: 1000px
  }
  header .top .nav_wrap {
    box-shadow: none !important
  }
  header .top .nav_wrap #menu_btn {
    left: 8px
  }
  header .top {
    max-width: 1000px;
    padding: 0
  }
  header .top .logo {
    width: 30%
  }
  header .top .work_shedule {
    width: 20%
  }
  header .top .nav_info {
    width: 100%;
    order: 2;
    text-align: center
  }
  header .top .work_shedule > div {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    text-align: center
  }
  header .top .work_shedule > div span {
    display: inline-block
  }
  header .top .work_shedule p {
    padding-left: 0;
    text-align: center
  }
  header .top .nav_info ul {
    margin-bottom: 5px;
    margin-top: 20px
  }
  #site_carousel .item .text {
    top: 80px
  }
  #site_carousel .owl-controls .owl-nav > div {
    width: 60px;
    height: 60px;
    -webkit-background-size: 30px;
    background-size: 30px
  }
  #site_carousel .owl-controls .owl-nav {
    height: 60px;
    top: 220px
  }
  .page_content .content_wrap .left .consult_nums p:nth-of-type(2) {
    font-size: 14px
  }
  .page_content .content_wrap .left .welcome_title .descr .img {
    text-align: center;
    width: 100%
  }
  .page_content .content_wrap .left .welcome_title .descr .img img {
    display: inline-block;
    max-width: 295px
  }
  .page_content .content_wrap .left .welcome_title .descr .text {
    width: 100%;
    padding-left: 15px;
    margin-top: 20px
  }
  .page_content .content_wrap .left .welcome_title {
    padding-bottom: 15px
  }
  .page_content .content_wrap .left .offers .items > div h5,
  .page_content .content_wrap .left .offers .items > div .subheader {
    font-size: 15px
  }
  .page_content .content_wrap .left .offers .items > div {
    width: 50%;
  }
  .page_content .content_wrap .left .offers .items {
    padding-left: 15px
  }
  .page_content .content_wrap .left .direction .items > div {
    border-bottom: 3px solid #fff
  }
  .page_content .content_wrap .left .direction .items > div .text {
    top: 28px
  }
  .page_content .content_wrap .left .direction .items > div .text h4,
  .page_content .content_wrap .left .direction .items > div .text .subheader {
    font-size: 18px;
    line-height: 19px
  }
  .page_content .content_wrap .left .direction .items > div .text h4:after,
  .page_content .content_wrap .left .direction .items > div .text .subheader:after {
    margin-top: 5px
  }
  .page_content .content_wrap .left .direction .items > div .logo {
    max-width: 185px
  }
  .page_content .content_wrap .right .subscribe_form > div button {
    position: static;
    width: 100%;
    margin-top: 12px
  }
  footer .items_wrap {
    padding-left: 15px;
    padding-right: 15px
  }
  footer .items_wrap .nav_info {
    width: 100%;
    order: 4;
    text-align: center;
    margin-top: 15px
  }
  footer .items_wrap .nav_info ul {
    margin-bottom: 10px
  }
  footer .items_wrap .work_shedule {
    width: 20%;
    text-align: center
  }
  footer .items_wrap .work_shedule p {
    padding-left: 0;
    margin-bottom: 0
  }
  footer .items_wrap .first {
    width: 40%
  }
  footer .items_wrap .first .count_and_soc > div:first-child {
    width: auto
  }
  .page_content .right .back_link {
    font-size: 14px
  }
  .page_content .right .order_consult form button {
    font-size: 12px;
    padding: 8px 0
  }
  .page_content .news .items .item .text .item_text_bottom {
    margin-top: 20px
  }
  .pagination .links .next,
  .pagination .links .prev {
    margin: 0
  }
  .map_page .contacts_info .img img {
    max-width: 260px;
    display: inline-block
  }
  .map_page .contacts_info .text {
    width: 100%;
    padding-left: 15px;
    margin-top: 20px;
    padding-right: 15px;
    text-align: center
  }
  #map {
    min-height: 300px
  }
  .service_page .content_wrap .left .direction .items > div .text h4 {
    font-size: 14px !important
  }
  .service_page .content_wrap .left .direction .items > div {
    border-bottom: none
  }
  .page_content .service_form form .top > div {
    width: 100% !important
  }
  .page_content .service_form form .top > div:not(:first-of-type) {
    margin-top: 15px
  }
  .typical_page_header {
    position: static !important
  }
  .typical_page_breadcrumbs {
    margin-top: 50px
  }
  .typical_page .content_wrap .left .typical_post .content .text .columns > div {
    width: 100%
  }
  .price_page.middle .content_wrap .full_width .table .text > div {
    padding: 10px 12px
  }
  #portfolio_overlay .img .owl-controls .owl-nav > div.owl-prev {
    left: 20px
  }
  #portfolio_overlay .img .owl-controls .owl-nav > div.owl-next {
    right: 20px
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a {
    text-transform: none
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a:after {
    width: 5px;
    height: 5px;
    right: 10px;
    top: 15px
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li:first-of-type {
    border-left: 1px solid #ddd
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li {
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd
  }
  .page_content.reviews_page_content .content_wrap .left .reviews_form .top .left,
  .page_content.reviews_page_content .content_wrap .left .reviews_form .top .right_block {
    width: 100%;
    padding-right: 0
  }
  .page_content.reviews_page_content .content_wrap .left .reviews_form .what_realised {
    margin-top: 0
  }
  //.schema_page .schema .content .items {
  //  flex-direction: column
  //}
  //.schema_page .schema .content .items > div {
  //  width: 280px;
  //  margin-left: auto;
  //  margin-right: auto;
  //  margin-bottom: 20px
  //}
  //.schema_page .schema .content .items > div:after {
  //  height: 15px;
  //  width: 3px;
  //  right: 138px;
  //  bottom: -15px;
  //  top: auto
  //}
  //.schema_page .schema .content .items > div:before {
  //  transform: rotate(135deg);
  //  bottom: -13px;
  //  right: 134px;
  //  top: auto
  //}
  //.schema_page .schema .content .items > div:last-of-type {
  //  margin-bottom: 0
  //}
  //.schema_page .schema .step {
  //  width: 280px
  //}
}

@media only screen and (max-width: 992px) {
  header .top .logo {
    width: 20%
  }
  header .top .work_shedule {
    width: 22%
  }
  header .top .work_shedule > div span {
    line-height: 19px
  }
  header .top .work_shedule p {
    margin-bottom: 0
  }
  header .top .contact_numbers_and_button {
    width: 38%
  }
  header .top .language {
    width: 10%
  }
  header .top .language a,
  header .top .language span {
    width: 100%
  }
  header .top .contact_numbers_and_button .order_consult {
    font-size: 14px;
    padding: 7px 0
  }
  header .top .nav_info ul {
    margin-top: 5px
  }
  #site_carousel .item img {
    min-height: 400px;
    max-height: 400px;
    width: auto
  }
  #site_carousel .item .text {
    top: 30px
  }
  #site_carousel .item .text h4 {
    font-size: 19px;
    line-height: 24px
  }
  #site_carousel .owl-controls .owl-nav {
    top: 170px
  }
  #site_carousel .owl-controls .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    margin: 0 7px
  }
  .page_content .content_wrap .left .consult_nums p:first-of-type {
    font-size: 14px;
    line-height: 19px
  }
  .page_content .content_wrap .left .consult_nums {
    padding-right: 55px;
    -webkit-background-size: 20px;
    background-size: 20px;
    padding-left: 40px;
    background-position: left 7px center
  }
  .page_content .content_wrap .left .consult_nums p:nth-of-type(2) br {
    display: block
  }
  .page_content .content_wrap .left .welcome_title .descr .img {
    width: 30%
  }
  .page_content .content_wrap .left .welcome_title .descr .text {
    width: 100%
  }
  .page_content .content_wrap .left .welcome_title .descr .text p img {
    max-width: 100%
  }
  .page_content .content_wrap .left .welcome_title h2,
  .page_content h3,
  .page_content .header {
    font-size: 19px;
    line-height: 24px
  }
  .page_content .content_wrap .left .offers .items > div h5,
  .page_content .content_wrap .left .offers .items > div .subheader {
    font-size: 14px;
    padding-top: 3px
  }
  .page_content .content_wrap .left .direction .items {
    justify-content: center
  }
  .page_content .content_wrap .left .direction .items > div {
    width: 40%;
    max-width: 370px
  }
  .page_content .content_wrap .left .direction .items > div:nth-child(2n) {
    border-left: 3px solid #fff
  }
  .page_content .content_wrap .left .direction .items > div:last-of-type {
    width: 240px
  }
  .page_content .content_wrap .left .direction .items > div .logo {
    max-width: 240px
  }
  .page_content .content_wrap .left .page_bottom_text {
    padding: 15px 0
  }
  .page_content .content_wrap {
    flex-wrap: wrap
  }
  .page_content .content_wrap .left {
    width: 100%
  }
  .page_content .content_wrap .right {
    width: 470px;
    border-left: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px
  }
  footer {
    padding-bottom: 25px
  }
  footer .items_wrap .first {
    width: 27%
  }
  footer .items_wrap .first .count_and_soc > div:first-child {
    width: 100%
  }
  footer .items_wrap .work_shedule {
    width: 25%
  }
  footer .items_wrap .contact_numbers_and_button {
    width: 37%
  }
  .page_content .right .download_links a {
    margin-top: 10px
  }
  .page_content .news .items .item .img {
    width: 25%
  }
  .page_content .news .items .item .text {
    width: 75%
  }
  .map_page .contacts_info .img {
    width: 35%;
    align-self: center
  }
  .map_page .contacts_info .img img {
    max-width: none;
    display: inline-block
  }
  .map_page .contacts_info .text {
    width: 65%;
    padding-left: 15px;
    margin-top: 0;
    padding-right: 15px;
    text-align: left
  }
  .service_page .content_wrap .left .direction .items > div:last-of-type,
  .typical_page .gallery > div {
    width: 40%;
    max-width: 370px
  }
  .service_page .content_wrap .left .direction .items > div {
    border-right: 5px solid #fff;
    border-left: none !important
  }
  .service_page .content_wrap .left .direction .items > div:nth-child(3n) {
    border-right: 5px solid #fff
  }
  .typical_page .gallery {
    justify-content: center
  }
  .typical_page .gallery > div:nth-child(2n) {
    border-right: none
  }
  .typical_page .gallery > div:nth-child(3n) {
    border-right: 5px solid #fff
  }
  #portfolio_overlay .img .owl-controls .owl-nav > div {
    -webkit-background-size: 15px;
    background-size: 15px;
    position: static;
    display: inline-block;
    background-color: rgba(36, 38, 43, .9);
    height: 45px;
    width: 45px;
    margin: 0 10px
  }
  #portfolio_overlay .img .owl-controls .owl-nav {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%
  }
  #portfolio_overlay .service_form h4 {
    padding-top: 10px;
    padding-bottom: 10px
  }
  #portfolio_overlay .service_form form .top > div {
    width: 100% !important;
    margin-bottom: 5px
  }
  #portfolio_overlay .service_form form .adress,
  #portfolio_overlay .service_form form .bottom,
  #portfolio_overlay .service_form form .services_select {
    margin-top: 5px
  }
  #portfolio_overlay .service_form form .top > div:last-of-type {
    margin-bottom: 0
  }
  .schema_page .schema .content .items > div .right {
    margin-top: 0
  }
}

@media only screen and (max-width: 768px) {
  .map_page .contacts_info .text,
  .reviews_page .items .item .text {
    padding-left: 15px;
    padding-right: 15px
  }
  header .top .nav_wrap {
    align-items: center;
    justify-content: space-around
  }
  header .top .contact_numbers_and_button {
    order: 3;
    width: 100%;
    margin: 15px 0
  }
  header .top .contact_numbers_and_button .order_consult {
    max-width: 300px
  }
  header .top .work_shedule {
    width: 200px;
    order: 2
  }
  header .top .language {
    order: 9;
    width: 15%
  }
  header .top .nav_info {
    order: 8;
    width: 80%;
    text-align: left
  }
  .page_content .map_page .contacts_info .img,
  .page_content .map_page .contacts_info .text,
  .page_content .news .items .item .img,
  .reviews_page .items .item .img,
  .reviews_page .items .item .text {
    text-align: center;
    width: 100%;
  }
  header .top .logo {
    width: 50%
  }
  #site_carousel .item .text {
    max-width: 400px;
    left: 20px;
    top: 10px
  }
  #site_carousel .owl-controls .owl-nav > div {
    width: 20px;
    height: 20px;
    -webkit-background-size: 12px;
    background-size: 12px;
    position: static;
    margin: 0 20px
  }
  #site_carousel .owl-controls .owl-nav {
    height: 20px;
    bottom: 15px;
    top: auto
  }
  #site_carousel .item .text p {
    line-height: 19px;
    font-size: 12px;
  }
  .typical_page_breadcrumbs {
    margin-top: 0
  }
  .page_content .content_wrap .left .welcome_title .descr .img,
  .page_content .content_wrap .left .welcome_title .descr .text {
    width: 100%
  }
  .page_content .content_wrap .left .about_company .video iframe {
    min-height: 350px
  }
  footer .items_wrap .first {
    width: 50%
  }
  footer .items_wrap .work_shedule {
    width: 35%
  }
  footer .items_wrap .contact_numbers_and_button {
    width: 62%;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto
  }
  footer .items_wrap .contact_numbers_and_button .order_consult {
    max-width: 300px
  }
  .map_page .contacts_info .img img,
  .page_content .news .items .item .img img,
  .reviews_page .items .item .img img {
    max-width: 260px;
    display: inline-block
  }
  .page_content .news .items .item .text {
    text-align: center;
    width: 100%;
    padding-left: 15px;
    padding-top: 20px
  }
  .map_page .contacts_info .img {
    align-self: center
  }
  .map_page .contacts_info .text {
    margin-top: 20px
  }
  .map_page .message_form form .top > div:not(:last-of-type) {
    width: 100%
  }
  .map_page .message_form form .top > div:first-of-type {
    padding-right: 0;
    margin-bottom: 15px
  }
  .map_page .message_form form .top > div:nth-of-type(2) {
    padding-left: 0
  }

  .price_page.middle .content_wrap .full_width .table_wrap .table {
    width: 100%;
    min-width: 700px;
  }

  .price_page.middle .content_wrap .full_width .table_wrap {
    padding-bottom: 15px;
    overflow: auto;
    max-height: 500px;
  }
  .price_page.middle .mobile_table {
    display: none;
    width: 100%;
    max-width: 360px;
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav .child li a,
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a {
    padding-top: 3px;
    padding-bottom: 3px
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav .parent_nav li a {
    padding-right: 20px
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a:after {
    top: 13px;
    right: 6px
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li {
    flex-grow: 0;
    margin-top: -1px;
    border: 1px solid #ddd;
    margin-right: -1px
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav ul,
  .portfolio_page .content_wrap .portfolio .text_block {
    flex-wrap: wrap
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav .child {
    padding-top: 1px
  }
  .portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a {
    padding-left: 15px;
    padding-right: 15px
  }
  .portfolio_page .content_wrap .portfolio .text_block > div:first-of-type h4 {
    margin-bottom: 20px
  }
  .portfolio_page .content_wrap .portfolio .text_block > div:first-of-type,
  .portfolio_page .content_wrap .portfolio .text_block > div:nth-of-type(2) {
    width: 50%
  }
  .portfolio_page .content_wrap .portfolio .text_block > div:last-of-type {
    width: 100%;
    padding-left: 0
  }
  #portfolio_overlay .order {
    flex-wrap: wrap
  }
  #portfolio_overlay .order > div:first-of-type {
    width: 100%
  }
  #portfolio_overlay .order > div:first-of-type span {
    font-size: 12px
  }
  #portfolio_overlay .order > div:last-of-type {
    width: 100%;
    text-align: center;
    margin-top: 10px
  }
  .page_content .service_form .bottom_btn.with_checkbox .checkbox {
    position: static;
    margin-top: 18px
  }
  .page_content .service_form .bottom_btn.with_checkbox .checkbox br {
    display: none
  }
  .page_content .our_team .items > div {
    width: 50%;
    max-width: 315px;
    border-right: none;
    padding: 15px 10px
  }
  .page_content .our_team .items > div:nth-child(2n - 1) {
    border-right: 1px solid #ddd;
    margin-left: auto
  }
  .page_content .our_team .items > div:nth-child(2n) {
    margin-right: auto
  }
  .page_content .reviews_form form .bottom > div:first-of-type {
    margin-top: 0
  }
  .page_content .reviews_form form .bottom > div {
    width: 100% !important;
    margin-top: 15px
  }
}

@media only screen and (max-width: 675px) {
  #hidden-form {
    max-width: 300px
  }
  #hidden-form form .flex_wrap .img {
    display: none
  }
  #hidden-form form .flex_wrap .inputs {
    padding-left: 0;
    width: 100%
  }
  .page_content .content_wrap .left .direction .items > div {
    width: 100%
  }
  .page_content .content_wrap .left .direction .items > div:nth-child(2n) {
    border-left: none
  }
  .page_content .content_wrap .left .direction .items > div:last-of-type {
    width: 100%
  }
  .typical_page .gallery > div {
    width: 100%;
    border-right: none
  }
  .typical_page .gallery > div:nth-child(3n) {
    border-right: none
  }
  #portfolio_overlay .service_form form .bottom > div {
    width: 100% !important;
    margin-bottom: 5px
  }
  #portfolio_overlay .service_form form .bottom > div:last-of-type {
    margin-bottom: 0
  }
  #portfolio_overlay .service_form .bottom_btn.with_checkbox .checkbox {
    position: static;
    text-align: center
  }
  #portfolio_overlay .service_form .bottom_btn.with_checkbox .checkbox label {
    display: inline-block;
    margin-top: 5px
  }
  #portfolio_overlay .service_form .bottom_btn.with_checkbox .checkbox label br {
    display: none
  }
}

@media only screen and (max-width: 480px) {
  .page_content .content_wrap .left .welcome_title h2,
  .page_content h2,
  .page_content h3,
  .page_content .header {
    font-size: 18px;
    line-height: 22px
  }
  footer .items_wrap .work_shedule > div,
  header .top .work_shedule > div {
    border: none
  }
  #portfolio_overlay .order > div:first-of-type,
  #portfolio_overlay .service_form .bottom_btn.with_checkbox > p {
    display: none
  }
  header .top .nav_wrap {
    padding: 10px;
    justify-content: space-around
  }
  header .top .logo {
    width: 100%
  }
  header .top .logo img {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto
  }
  header .top .work_shedule {
    width: 50%;
    margin-top: 15px;
    display: none;
  }
  header .top .nav_info {
    display: none;
  }
  header .top .contact_numbers_and_button {
    margin-top: 10px;
    order: 4;
    margin-bottom: 0;
    div {
      display: none;
    }
  }
  header .top .language {
    order: 3;
    width: 30%;
    display: none;
  }
  .page_content .content_wrap .left .offers .items > div,
  footer .items_wrap .contact_numbers_and_button,
  footer .items_wrap .first,
  footer .items_wrap .first .count_and_soc > div:nth-child(2),
  header .top .nav_info {
    width: 100%
  }
  #site_carousel .item .text {
    max-width: 300px;
    left: 10px;
    padding: 8px;
    p {
      display: none;
    }
  }
  #site_carousel .item .text h4 {
    font-size: 13px;
    margin: 0;
  }
  #site_carousel .item img {
    min-height: 210px;
    max-height: 210px
  }
  .page_content {
    .content_wrap {
      .left {
        .consult_nums {
          padding-top: 10px;
          background: none;
          padding-left: 10px;
          padding-right: 10px;
          background-position: left 17px top 17px;
        }
      }
    }
  }
  .page_content .content_wrap .left .consult_nums p:first-of-type {
    margin-bottom: 10px
  }
  .page_content .content_wrap .left .welcome_title .descr .bottom_text .mission {
    margin-top: 10px
  }
  .page_content .content_wrap .left .about_company .video iframe {
    min-height: 200px
  }
  .why_change_us .links a {
    font-size: 14px;
    padding-left: 52px;
    padding-right: 12px
  }
  .why_change_us .links a .ic {
    left: 10px
  }
  footer .items_wrap .first {
    text-align: center
  }
  footer .items_wrap .work_shedule {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px
  }
  #portfolio_overlay .order > div:last-of-type,
  #portfolio_overlay .service_form .bottom_btn.with_checkbox button {
    margin-top: 0
  }
  .page_content .news .select_cat form {
    width: 280px
  }
  .page_content .news .select_cat form:after {
    top: 18px
  }
  .page_content .news .select_cat form select {
    font-size: 14px;
    padding: 8px
  }
  .map_page .message_form h4 {
    font-size: 14px
  }
  .map_page .message_form form button {
    font-size: 14px;
    width: 100%
  }
  .page_content .service_form .bottom_btn.with_checkbox button {
    width: 100%
  }
  .typical_page .content_wrap .left .typical_post .content .text {
    padding-top: 20px
  }
  .typical_page .content_wrap .left .typical_post .content .text .columns > div p {
    padding-left: 20px;
    padding-right: 5px
  }
  .portfolio_page .content_wrap .portfolio .text_block > div:first-of-type,
  .portfolio_page .content_wrap .portfolio .text_block > div:nth-of-type(2) {
    padding-top: 0;
    width: 50%;
  }
  #portfolio_overlay .order,
  #portfolio_overlay .service_form h4 {
    padding-top: 5px;
    padding-bottom: 5px
  }
  .page_content .reviews_form .bottom_btn.with_checkbox button {
    width: 100%
  }
  .schema_page .schema .content .items > div {
    max-width: 270px;
  }
  .schema_page .schema .step {
    width: 270px
  }
}

@media only screen and (max-width: 320px) {
  .home_page .content_wrap .left .direction .items > div:last-of-type,
  .page_content .content_wrap .left #second_menu_btn,
  footer .items_wrap .first .count_and_soc > div:first-child {
    display: none;
  }
  .news_page .left .subscribe_form > div button {
    position: static;
    margin-top: 15px;
    width: 100%
  }
  header .top .contact_numbers_and_button > div {
    margin-bottom: 3px
  }
  .page_content .content_wrap .left .welcome_title .descr .bottom_text .mission {
    margin-top: 20px
  }
}

@media only screen and (max-width: 2000px) {
  .page_content .content_wrap .right .clients .items > div img {
    max-width: 190px;
    display: inline-block
  }
}

/*Edits on site*/

.portfolio_page .content_wrap .portfolio .items_gallery > div > .project_item {
  border-right: 8px solid #fff;
  @media (max-width: $tab) {
    border-top: 8px solid #fff;
    &:nth-of-type(2n) {
      border-right: none;
    }
  }
}

.portfolio_page .content_wrap .portfolio .items_gallery > div > .project_item:nth-child(3n) {
  border-right: none;
  @media (max-width: $tab) {
    border-right: 8px solid #fff;
  }
  @media (max-width: $mob) {
    border-right: none;
  }
}

@media (max-width: 1024px) {
  .portfolio_page .content_wrap .portfolio .items_gallery > div > .project_item {
    height: 220px;
  }
}

@media (max-width: 767px) {
  .portfolio_page .content_wrap .portfolio .items_gallery > div {
    flex-wrap: wrap;
    border-bottom: none;
  }
  .portfolio_page .content_wrap .portfolio .items_gallery > div > .project_item {
    width: 100%;
    border-right: none;
  }
}

header .top .nav_wrap {
  @media (max-width: $mob) {
    padding-top: 60px;
  }

  #menu_btn {
    @media (max-width: $mob) {
      position: fixed;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      right: auto;
      bottom: auto;
      background-color: #111;
      width: 95%;
      background-image: none;
      font-size: 14px;
      margin-bottom: 20px;
      line-height: 45px;
      text-transform: uppercase;
      padding: 0;
      height: 45px;
      .mob-icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-background-size: 24px;
        background-size: 24px;
        background-image: url(../img/ics/menu-button.svg);
        margin-bottom: -9px;
        margin-left: 5px;
      }
    }
  }
}


.page_content.service_page .direction {
  h2 {
    font-size: 27px;
    text-transform: uppercase;
    line-height: 36px;
    @media (max-width: $custom-media){
      font-size: 22px;
      line-height: 32px;
    }
    @media (max-width: $mob) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  h4 {
    font-size: 21px;
    @media (max-width: $custom-media) {
      font-size: 18px;
    }
  }
}
