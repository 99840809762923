.typical_page_breadcrumbs {
  margin-top: 207px
}

.typical_page .content_wrap .right .order_consult {
  border-bottom: none
}

.typical_page .content_wrap .left .typical_post {
  border-bottom: 8px solid #F2F4F7
}

.typical_page .content_wrap .left .typical_post .title-big {
  margin-bottom: 14px;
}

.typical_page .content_wrap .left .typical_post .title {
  padding-top: 30px;
  padding-right: 30px
}

.content_wrap .left .content .text {
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  color: #000
}

.typical_page .content_wrap .left .typical_post .content .text h4 {
  font-family: OpenSansBold;
  font-size: 24px;
  margin-bottom: 20px
}

.typical_page .content_wrap .left .typical_post .content .text p {
  font-size: 16px
}

.typical_page .content_wrap .left .typical_post .content .text p.bold {
  font-family: OpenSansBold;
  margin: 20px 0
}

.typical_page .content_wrap .left .typical_post .content .text .columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 40px
}

.typical_page .content_wrap .left .typical_post .content .text .columns>div {
  width: 50%
}

.typical_page .content_wrap .left .typical_post .content .text .columns>div p {
  margin-bottom: 20px;
  position: relative;
  padding-left: 35px;
  padding-right: 25px
}

.typical_page .content_wrap .left .typical_post .content .text .columns>div p:before {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #E92C30;
  position: absolute;
  left: 0;
  top: 10px
}