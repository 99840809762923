.portfolio_page .content_wrap .full_width {
  width: 100%;
  background-color: #fff;
  border-bottom: 8px solid #F2F4F7
}

.portfolio_page .content_wrap .portfolio .title {
  padding-right: 30px;
  padding-top: 30px
}

.portfolio_page .content_wrap .portfolio .portfolio_nav {
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 20px
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  border-top: 3px solid #DDD;
  border-bottom: 3px solid #DDD;
  border-right: 3px solid #DDD
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li:first-of-type {
  border-left: 3px solid #DDD
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a {
  display: block;
  text-align: center;
  color: #000;
  text-transform: uppercase;
  font-family: OpenSansBold;
  font-size: 14px;
  padding: 12px 0;
  position: relative
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a:after {
  display: block;
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 44px;
  top: 20px
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a.active,
.portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a:hover {
  background-color: #4B4D50;
  color: #fff
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a.active:after,
.portfolio_page .content_wrap .portfolio .portfolio_nav nav ul li a:hover:after {
  border-color: #fff
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav .child {
  background-color: #B82827;
  display: none
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav .child li {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border-color: #B82827
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav .child li:first-of-type {
  border-color: #B82827
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav .child li a {
  background-color: #494D55;
  color: #fff;
  padding: 18px 25px
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav .child li a:after {
  display: none
}

.portfolio_page .content_wrap .portfolio .portfolio_nav nav .child li a:hover {
  background-color: #383A3F
}

.portfolio_page .content_wrap .portfolio .items_gallery > div:last-of-type {
  border-bottom: none
}

.portfolio_page .content_wrap .portfolio .items_gallery > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 8px solid #fff;
  flex-wrap: wrap;
}

.portfolio_page {
  .content_wrap {
    .portfolio {
      .items_gallery {
        .project_item {
          cursor: pointer;
          -webkit-transition: all .4s ease;
          transition: all .4s ease;
          height: 290px;
          display: block;
          background-repeat: no-repeat;
          background-position: center center;
          -webkit-background-size: cover;
          background-size: cover;
          width: 33.33%;
          position: relative;
          background-color: #e5e5e5;
          @media (max-width: $tab){
            width: 50%;
          }
          .project-name {
            display: inline-block;
            font-family: "Exo2";
            font-weight: 400;
            text-transform: uppercase;
            font-size: 18px;
            background-color: rgba(#000, 0.7);
            color: #fff;
            padding: 5px 10px;
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 240px;
            min-height: 58px;
            max-height: 200px;
            overflow-y: hidden;
          }
        }
      }
    }
  }
}

.portfolio_page .content_wrap .portfolio .items_gallery > div > .project_item.need-file {
  -webkit-background-size: 128px;
  background-size: 128px;
}

.portfolio_page .content_wrap .portfolio .items_gallery {
  > div > .project_item a {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  &.sorted-by-years {
    > div > .project_item {
      -webkit-background-size: cover;
      background-size: cover;
      &:hover {
        opacity: 1;
        a {
          opacity: 0;
        }
        .category_name {
          transform: scale(0.6);
          opacity: 0;
        }
      }
      a {
        display: flex;
        background-color: rgba(#000, .55);
        transition: all .6s ease;
        .category_name {
          font-family: "Exo2";
          margin: auto;
          font-weight: 400;
          font-size: 30px;
          text-transform: uppercase;
          display: inline-block;
          color: #fff;
          padding: 20px 50px;
          border: 2px solid #fff;
          transition: all .3s ease;
          @media (max-width: $mob) {
            padding: 15px 32px;
            font-size: 24px;
          }
        }
      }
    }
  }
}

.portfolio_page .content_wrap .portfolio .items_gallery {
  > div > div:hover {
    opacity: .7
  }
}

.portfolio_page {
  .content_wrap {
    .portfolio {
      .text_block {
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding: 20px 30px 45px;
        .text-item {
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          p {
            width: 50%;
          }
        }
      }
    }
  }
}

.portfolio_page .content_wrap .portfolio .text_block > div {
  color: #000
}

.portfolio_page .content_wrap .portfolio .text_block > div h4 {
  font-family: OpenSansBold;
  font-size: 16px
}

.portfolio_page .content_wrap .portfolio .text_block > div p {
  font-size: 14px
}

.portfolio_page .content_wrap .portfolio .text_block > div:first-of-type {
  width: 40%
}

.portfolio_page .content_wrap .portfolio .text_block > div:first-of-type h4 {
  margin-bottom: 40px
}

.portfolio_page .content_wrap .portfolio .text_block > div:first-of-type p {
  margin-bottom: 15px
}

.portfolio_page .content_wrap .portfolio .text_block > div:first-of-type p:nth-of-type(2) {
  margin-bottom: 40px
}

.portfolio_page .content_wrap .portfolio .text_block > div:nth-child(2) p {
  margin-bottom: 15px
}

.portfolio_page .content_wrap .portfolio .text_block > div:last-of-type {
  width: 60%;
  padding-left: 30px
}

.portfolio_page .content_wrap .portfolio .text_block > div:last-of-type h4 {
  margin-bottom: 22px
}

.portfolio_page .content_wrap .portfolio .text_block > div:last-of-type .client_review {
  margin-top: 22px;
  padding-top: 22px;
  border-top: 1px solid #EFEFEF
}

.portfolio_page .content_wrap .portfolio .text_block > div:last-of-type .client_review span {
  color: #999;
  font-family: OpenSansBold
}

.portfolio_page .content_wrap .portfolio .text_block > div:last-of-type .client_review a {
  display: inline-block;
  margin-top: 18px;
  color: #000;
  font-family: OpenSansBold
}

.portfolio_page .content_wrap .portfolio .text_block > div:last-of-type .client_review a:hover {
  color: #F45C70
}