.page_content {
  .content_wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .block-pd {
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .block-border {
      border-bottom: 8px solid #F2F4F7
    }
  }
  .left, .right {
    background-color: #fff;
  }
  .left {
    width: 70%;
    position: relative;
    .consult_nums {
      padding: 14px 20px 14px 90px;
      background-repeat: no-repeat;
      background-position: left 30px center;
      background-image: url(../img/ics/phone.png);
      border-bottom: 8px solid #F2F4F7;
      .txt {
        color: #B82827;
        font-family: "OpenSans";
        font-weight: 600;
        font-size: 18px;
      }
      .num {
        font-size: 24px;
        color: #000;
        margin-top: 8px;
        line-height: 30px;
        font-weight: 600;
        br {
          display: none;
        }
        span {
          font-weight: 600;
          font-family: "OpenSans";
        }
      }
    }
    .home-descr {
      display: flex;
      padding-left: 20px;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      color: #000;
      span {
        font-family: "OpenSans"!important;
      }
      .text {
        font-size: 17px;
        @media (max-width: $custom-media) {
          font-size: 12px;
        }
      }
      img {
        margin-right: 20px;
        margin-bottom: 20px;
        @media (max-width: $mob){
          width: 100%!important;
          height: auto!important;
          float: none;
          margin: 15px auto;
        }
      }
      .bottom {
        width: 100%;
        margin-top: 15px;
        font-size: 17px;
        .mission-btn {
          display: block;
          width: 250px;
          margin-left: auto;
          font-size: 16px;
          padding: 8px 0;
        }
      }
    }
    .offers {
      .items {
        color: #000;
        padding-left: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        .service-item {
          width: 33%;
          margin-bottom: 30px;
          position: relative;
          padding-left: 55px;
          @media (max-width: $custom-media){
            padding-left: 34px;
          }
          &:before {
            display: block;
            content: "";
            width: 37px;
            height: 37px;
            background-color: #E92C30;
            position: absolute;
            top: 0;
            left: 0;
          }
          &:after {
            display: block;
            content: "";
            height: 35px;
            width: 9px;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            position: absolute;
            left: 20px;
            top: -8px;
            transform: rotate(45deg);
          }
          .header {
            font-family: "OpenSans";
            font-weight: 600;
            text-transform: uppercase;
            font-size: 22px;
            line-height: 20px;
            padding-left: 0;
            margin-bottom: 10px;
            @media (max-width: $custom-media){
              font-size: 15px;
              margin-bottom: 4px;
            }
          }
          .subheader {
            font-size: 16px;
            line-height: 20px;
            @media (max-width: $custom-media){
              font-size: 12px;
            }
          }
        }
      }
    }
    .why_change_us {
      .links {
        padding-left: 20px;
        padding-top: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        a {
          display: block;
          width: 32%;
          padding: 15px 20px 15px 65px;
          margin-bottom: 20px;
          border: 1px solid #D2D2D2;
          color: #000;
          font-family: "OpenSans";
          font-weight: 600;
          text-transform: uppercase;
          font-size: 16px;
          position: relative;
          @media (max-width: $custom-media){
            font-size: 14px;
            text-transform: none;
          }
          @media (max-width: $small-lap){
            width: 100%;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
          &:hover {
            text-decoration: none;
            border-color: #000;
            .ic:after {
              left: -35px;
            }
            .ic:before {
              left: 3px;
            }
          }
          .ic {
            display: block;
            position: absolute;
            width: 32px;
            height: 32px;
            background-color: #E92C30;
            left: 17px;
            top: 11px;
            overflow: hidden;
            &:after {
              display: block;
              content: "";
              width: 50px;
              height: 1px;
              background-color: #fff;
              position: absolute;
              top: 15px;
              left: -29px;
              transition: all .4s ease;
            }
            &:before {
              display: block;
              content: "";
              width: 11px;
              height: 11px;
              transform: rotate(45deg);
              border-top: 1px solid #fff;
              border-right: 1px solid #fff;
              position: absolute;
              left: 8px;
              top: 10px;
              -webkit-transition: all .4s ease;
              transition: all .4s ease
            }
          }
        }
        &.block-pd {
          padding-top: 0;
        }
      }
    }
    .direction {
      border-bottom: 8px solid #F2F4F7;
      .block-pd {
        padding-bottom: 0;
      }
      .content {
        .text {
          padding-top: 0;
        }
      }
      .items {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .item {
          width: 33%;
          min-height: 185px;
          border-bottom: 5px solid #fff;
          overflow: hidden;
          position: relative;
          display: flex;
          .logo {
            position: static;
            margin: auto;
            max-width: 244px
          }
          img {
            transition: all .4s ease
          }
          .text {
            color: #fff;
            z-index: 200;
            text-align: center;
            border: 2px solid #fff;
            margin: auto;
            width: 90%;
            padding-top: 25px;
            padding-bottom: 25px;
            transition: all .4s ease;
            position: absolute;
            left: 5%;
            top: 37px;
            .smallheader {
              font-size: 12px;
              text-transform: uppercase
            }
            .subheader {
              font-family: "Exo2";
              font-weight: 400;
              font-size: 30px;
              text-transform: uppercase;
              position: relative;
              &:after {
                display: block;
                content: "";
                width: 55px;
                height: 1px;
                background-color: #fff;
                margin-left: auto;
                margin-right: auto;
                margin-top: 15px
              }
            }
          }
        }
      }
    }
    .about_company {
      .video {
        padding-left: 20px;
        iframe {
          width: 100%;
          border: none;
          min-height: 500px;
        }
      }
    }
    .page_bottom_text {
      padding-bottom: 20px;
      padding-top: 20px;
      padding-left: 0px;
      padding-right: 20px;
      background-color: #F2F4F7;
      overflow: hidden;
      max-height: 250px;
      h1,h2,h3,h4,h5,h6 {
        font-weight: 600;
        padding-left: 0;
      }
      &.opened {
        max-height: none
      }
      p {
        font-size: 14px;
        color: #666
      }
      & + #toggle_bottom_text {
        position: relative;
        cursor: pointer;
        background-color: #F2F4F7;
        &:before {
          display: block;
          content: "";
          height: 85px;
          width: 100%;
          position: absolute;
          bottom: 22px;
          left: 0;
          box-shadow: inset 0 -40px 80px #F2F4F7;
        }
        &:after {
          display: block;
          content: "";
          width: 30px;
          height: 30px;
          border-bottom: 2px solid #9E9E9E;
          border-right: 2px solid #9E9E9E;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          margin-left: auto;
          margin-right: auto;
          &:hover {
            border-color: #F45C70;
          }
        }
        &.active {
          transform: rotate(180deg);
          &:before {
            display: none;
          }
        }
      }
    }
    .service_form, .reviews_form {
      .title {
        color: #fff;
        text-transform: uppercase;
        font-size: 24px;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #24262B;
        font-weight: 600;
        font-family: "Exo2";
      }
      form {
        padding: 0 20px 40px;
        background-color: #24262B;
        .top {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
          .wrapper {
            position: relative;
            input {
              width: 100%;
              display: inline-block;
              border: none;
              height: 50px;
              padding-left: 45px;
              padding-right: 20px;
              font-size: 16px;
              color: #000;
              background-repeat: no-repeat;
              background-position: left 20px center;
              &:focus ~ label {
                display: none;
              }
            }
            &:first-of-type {
              input {
                background-image: url(../img/ics/user.png);
              }
            }
            &:nth-child(2) {
              input {
                background-image: url(../img/ics/small_phone.png);
              }
            }
            &:not(:last-of-type) {
              width: 30%;
            }
            &:last-of-type {
              width: 37%;
              input {
                background-image: url(../img/ics/envelope.png);
              }
            }
            label {
              position: absolute;
              color: #000;
              font-size: 16px;
              left: 45px;
              top: 12px;
              @media (max-width: $custom-media){
                font-size: 14px;
                top: 14px;
              }
              @media (max-width: $tab) {
                font-size: 12px;
              }

              span {
                color: #E92C30;
              }
            }
          }
        }
        .adress {
          position: relative;
          margin-top: 15px;
          input {
            width: 100%;
            display: inline-block;
            border: none;
            height: 50px;
            padding-left: 45px;
            padding-right: 20px;
            font-size: 16px;
            color: #000;
            background-repeat: no-repeat;
            background-position: left 20px center;
            background-image: url(../img/ics/home.svg);
            -webkit-background-size: 19px;
            background-size: 19px;
            &:focus ~ label {
              display: none;
            }
          }
          label {
            position: absolute;
            color: #000;
            font-size: 16px;
            left: 45px;
            top: 12px;
            @media (max-width: $custom-media){
              font-size: 14px;
              top: 14px;
            }
            @media (max-width: $tab){
              font-size: 12px;
            }
            span {
              color: #E92C30;
            }
          }
        }
        .services_select {
          margin-top: 15px;
          position: relative;
          &:after {
            display: block;
            content: "";
            width: 10px;
            height: 10px;
            border-top: 2px solid #000;
            border-right: 2px solid #000;
            position: absolute;
            top: 20px;
            right: 15px;
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
          }
          .checks_items_list {
            min-height: 50px;
            padding-left: 20px;
            padding-right: 20px;
            background-color: #fff;
            position: relative;
            .service_form_checks {
              box-shadow: 0px 5px 5px rgba(#000, .1);
              position: absolute;
              left: 0;
              background-color: #fff;
              z-index: 100;
              top: 50px;
              width: 100%;
              border-top: 1px solid #F45C70;
              display: none;
              &.active {
                display: block;
              }
              .checkbox-wrapper {
                input {
                  display: none;
                  &:checked ~ label:after {
                    opacity: 1;
                  }
                }
                label {
                  display: block;
                  font-size: 16px;
                  position: relative;
                  padding: 5px 15px 5px 40px;
                  transition: all .3s ease;
                  @media (max-width: $custom-media){
                    font-size: 12px;
                  }
                  &:hover {
                    background-color: #e5e5e5;
                    cursor: pointer;
                  }
                  &:before {
                    display: block;
                    content: "";
                    width: 20px;
                    height: 20px;
                    border: 2px solid #E92C30;
                    position: absolute;
                    left: 10px;
                    top: 7px;
                  }
                  &:after {
                    display: block;
                    content: "";
                    width: 7px;
                    height: 12px;
                    border-bottom: 2px solid #E92C30;
                    border-right: 2px solid #E92C30;
                    position: absolute;
                    left: 16px;
                    top: 10px;
                    transform: rotate(45deg);
                    opacity: 0;
                    transition: all .4s ease
                  }
                }
              }
            }
            span {
              color: #000;
              display: block;
              height: 50px;
              line-height: 50px;
              font-size: 16px;
              &.added {
                display: inline;
              }
            }
            .open_checks {
              color: #000;
              display: block;
              line-height: 50px;
              font-size: 16px;
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              padding-left: 20px;
              @media (max-width: $custom-media){
                font-size: 14px;
              }
              @media (max-width: $tab){
                font-size: 12px;
              }
            }
          }
        }
        .bottom {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 15px;
          > div:not(:last-of-type) {
            width: 30%;
            @media (max-width: $small-lap){
              width: 100%;
              margin-bottom: 15px;
            }
          }
          > div:last-of-type {
            width: 37%;
            @media (max-width: $small-lap){
              width: 100%;
              margin-bottom: 15px;
            }
          }
          div {
            input {
              display: none;
            }
            .add_file_label {
              background-repeat: no-repeat;
              background-position: right 20px center;
              background-image: url(../img/ics/add.png);
              padding-left: 17px;
            }
            .add_new_input, label {
              cursor: pointer;
              display: block;
              height: 50px;
              border: 2px solid #666;
              color: #fff;
              font-size: 16px;
              line-height: 45px;
              position: relative;
              @media (max-width: $custom-media){
                font-size: 14px;
              }
              @media (max-width: $tab){
                font-size: 12px;
              }
              &:hover {
                border-color: #fff;
              }
            }
            .add_new_input.disabled,
            label.disabled {
              opacity: .4;
              cursor: not-allowed;
              &:hover {
                border-color: #666;
              }
            }
            .add_new_input {
              padding-left: 40px;
              position: relative;
              &:before {
                display: block;
                content: "";
                width: 10px;
                height: 2px;
                background-color: #fff;
                position: absolute;
                left: 15px;
                top: 22px;
              }
              &:after {
                display: block;
                content: "";
                width: 2px;
                height: 10px;
                background-color: #fff;
                position: absolute;
                left: 19px;
                top: 18px
              }
            }
          }
        }
      }
      #files_added {
        margin-top: 5px;
        li {
          color: #fff;
          font-size: 12px;
        }
      }
      .warning {
        color: #fff;
        font-size: 12px;
      }
      .bottom_btn.with_checkbox {
        text-align: center;
        position: relative;
        p {
          color: #fff;
          font-size: 16px;
          margin-top: 15px;
          @media (max-width: $custom-media){
            font-size: 12px;
          }
        }
        button {
          display: inline-block;
          width: 100%;
          max-width: 470px;
          background-color: #E92C30;
          border: none;
          font-family: OpenSansBold;
          color: #fff;
          text-transform: uppercase;
          font-size: 16px;
          padding: 12px 0;
          margin-top: 15px;
          &:hover {
            background-color: #F45C70;
          }
        }
        .checkbox {
          position: absolute;
          left: 60px;
          top: 50px;
          @media (max-width: $custom-media) {
            position: static;
            margin-top: 15px;
            text-align: center;
          }
          input {
            display: none;
            &:checked ~ label:after {
              opacity: 1;
              -webkit-transform: rotate(33deg);
              transform: rotate(33deg);
            }
          }
          label {
            color: #fff;
            font-size: 16px;
            text-align: left;
            display: block;
            position: relative;
            padding-left: 35px;
            line-height: 19px;
            @media (max-width: $custom-media) {
              display: inline-block;
              font-size: 12px;
              br {
                display: none;
              }

            }
            &:before {
              display: block;
              content: "";
              width: 18px;
              height: 18px;
              border: 2px solid #fff;
              position: absolute;
              left: 0;
              top: 2px;
            }
            &:after {
              display: block;
              content: "";
              width: 8px;
              height: 15px;
              border-bottom: 2px solid #E92C30;
              border-right: 2px solid #E92C30;
              position: absolute;
              left: 6px;
              top: 0;
              -webkit-transform: rotate(0);
              transform: rotate(0);
              opacity: 0;
              -webkit-transition: all .4s ease;
              transition: all .4s ease;
            }
          }
        }
      }
    }
    .reviews_form {
      form {
        .top {
          .left {
            .wrapper {
              width: 100%;
            }
          }
          .right_block {
            .wrapper {
              width: 100%;
              label {
                left: 20px;
              }
            }
          }
        }
      }

    }
  }
  .right {
    width: 30%;
    border-left: 8px solid #F2F4F7;
    .title {
      padding-left: 20px;
      .title-big {
        padding-right: 12px;
      }
    }
    .last_projects {
      padding-top: 20px;
      border-top: 8px solid #F2F4F7;
      .title-big {
        margin-bottom: 0;
      }
      .items {
        .item {
          position: relative;
          padding-left: 30px;
          padding-top: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #DDD;
          .image-block {
            height: 240px;
            background-repeat: no-repeat;
            background-position: center center;
            -webkit-background-size: cover;
            background-size: cover;
            background-color: #e5e5e5;
            @media (max-width: $custom-media){
              height: 165px;
            }
          }
          > a {
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 100;
          }
          .title {
            font-family: "Exo2";
            font-weight: 400;
            text-transform: uppercase;
            font-size: 18px;
            background-color: rgba(0, 0, 0, .7);
            color: #fff;
            padding: 5px 10px;
            position: absolute;
            bottom: 30px;
            left: 40px;
            width: 240px;
          }
          .date {
            position: absolute;
            font-size: 14px;
            background-color: #E92C30;
            color: #fff;
            padding: 10px 25px;
            right: 10px;
            bottom: 30px;
          }
        }
      }
      .go_to_portfolio {
        padding-left: 30px;
        border-bottom: 8px solid #F2F4F7;
        a {
          font-family: "OpenSans";
          font-weight: 600;
          padding-top: 25px;
          padding-bottom: 25px;
          color: #000;
          text-transform: uppercase;
          display: block;
          &:hover {
            color: #F45C70;
          }
        }
      }
    }
    .news_and_events {
      padding-top: 20px;
      .items {
        > div {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          color: #000;
          padding-top: 20px;
          padding-bottom: 25px;
          border-bottom: 1px solid #DDD;
          padding-left: 30px;
          .text {
            width: 56%;
            text-align: right;
            p {
              font-size: 14px;
              line-height: 20px;
              margin-top: 20px;
            }
          }
          .img {
            width: 36.5%;
          }
          .bottom {
            width: 100%;
            text-align: right;
            margin-top: 25px;
            padding-right: 20px;
            a, span {
              display: inline-block;
            }
            span {
              margin-right: 40px;
              color: #666;
              font-size: 14px;
            }
            a {
              font-family: "OpenSans";
              font-weight: 600;
              color: #000;
              position: relative;
              &:hover {
                text-decoration: underline;
                color: #F45C70;
              }
              &:before {
                display: block;
                content: "";
                width: 6px;
                height: 3px;
                background-color: #F45C70;
                position: absolute;
                left: -24px;
                top: 11px;
              }
            }
          }
          .title {
            font-family: "OpenSans";
            font-weight: 600;
            line-height: 20px;
            font-size: 14px;
            a {
              color: #000;
              &:hover {
                text-decoration: underline;
                color: #F45C70;
              }
            }
          }
        }
      }
      .read_all {
        padding-left: 30px;
        border-bottom: 8px solid #F2F4F7;
        a {
          font-family: "OpenSans";
          font-weight: 600;
          padding-top: 25px;
          padding-bottom: 25px;
          color: #000;
          text-transform: uppercase;
          display: block;
          &:hover {
            color: #F45C70;
          }
        }
      }
    }
    .subscribe_form {
      background-color: #24262B;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      > div {
        position: relative;
        label {
          display: block;
          color: #CCC;
          font-size: 18px;
          padding-left: 15px;
          padding-bottom: 20px;
          padding-top: 20px;
        }
        input {
          width: 100%;
          background-color: #fff;
          height: 44px;
          border: none;
          font-size: 18px;
          padding: 0 15px;
        }
        button {
          position: absolute;
          width: 130px;
          color: #fff;
          text-transform: uppercase;
          font-size: 16px;
          height: 44px;
          right: 0;
          bottom: 0;
          background-color: #E92C30;
          border: none;
          &:hover {
            background-color: #F45C70;
          }
        }
      }
    }
    .reviews {
      padding-top: 30px;
      padding-left: 20px;
      @media (max-width: $custom-media) {
        padding-left: 15px;
      }
      .all_reviews {
        padding-left: 30px;
        border-bottom: 8px solid #F2F4F7;
        a {
          font-family: OpenSansBold;
          padding-top: 25px;
          padding-bottom: 25px;
          color: #000;
          text-transform: uppercase;
          display: block;
          &:hover {
            color: #F45C70;
          }
        }
      }
    }
  }
}

.page_content .content_wrap .right .reviews .items > div {
  text-align: right;
  color: #000;
  border-bottom: 1px solid #DDD;
  padding: 25px 45px 25px 30px
}

.page_content .content_wrap .right .reviews .items > div .name {
  font-family: OpenSansBold;
  font-size: 16px
}

.page_content .content_wrap .right .reviews .items > div span {
  display: block;
  color: #999;
  font-size: 14px;
  margin-bottom: 30px
}

.page_content .content_wrap .right .reviews .items > div p {
  font-size: 14px
}

.page_content .content_wrap .right .reviews .items > div a {
  display: inline-block;
  font-family: OpenSansBold;
  color: #000;
  font-size: 16px;
  margin-top: 25px
}

.page_content .content_wrap .right .reviews .items > div a:hover {
  text-decoration: underline;
  color: #F45C70
}

.page_content .content_wrap .right .clients {
  padding-top: 28px
}

.page_content .content_wrap .right .clients .items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.page_content .content_wrap .right .clients .items > div {
  position: relative
}

.page_content .content_wrap .right .clients .items > div > a {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0
}

.page_content .content_wrap .right .clients .all_clients {
  padding-left: 30px
}

.page_content .content_wrap .right .clients .all_clients a {
  font-family: OpenSansBold;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #000;
  text-transform: uppercase;
  display: block
}

.page_content .content_wrap .right .clients .all_clients a:hover {
  color: #F45C70
}

.page_content {
  .our_team {
    .block-pd {
      padding-bottom: 0;
      .title-big {
        margin-bottom: 0;
      }
    }
  }
}

.page_content .our_team .title h3 {
  margin-bottom: 22px
}

.page_content .our_team .items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.page_content .our_team .items > div {
  width: 33.33%;
  padding: 32px 30px 25px
}

.page_content .our_team .items > div img {
  margin: 0 auto;
  border-top: 1px solid #F45C70;
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
  -webkit-transition: all .4s ease;
  transition: all .4s ease
}

.page_content .our_team .items > div img:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0)
}

.page_content .our_team .items > div p:first-of-type {
  font-family: OpenSansBold;
  margin-top: 20px
}

.page_content .our_team .items > div p {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 22px;
  color: #000
}

.page_content .our_team .items > div a {
  color: #E92C30;
  text-transform: none
}

.page_content .our_team .items > div a:hover {
  text-decoration: underline
}

.page_content .right .back_link {
  display: block;
  padding: 25px 30px 25px 65px;
  text-transform: uppercase;
  color: #000;
  background-repeat: no-repeat;
  background-position: left 30px center;
  background-image: url(../img/ics/link_prev.png);
  font-size: 18px;
  line-height: 20px;
  border-bottom: 8px solid #F2F4F7
}

.page_content .right .back_link:focus {
  outline: 0;
  text-decoration: none
}

.page_content .right .back_link:hover {
  color: #E92C30
}

.page_content .right .download_links {
  border-bottom: 8px solid #F2F4F7;
  padding: 20px 30px 32px
}

.page_content .right .download_links a {
  display: block;
  font-size: 18px;
  color: #000;
  margin-top: 22px;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url(../img/ics/pdf.png);
  padding: 15px 10px 15px 59px
}

.page_content .right .download_links a:hover {
  opacity: .6
}

.page_content {
  .right {
    .order_consult {
      color: #000;
      padding-left: 20px;
      padding-bottom: 20px;
      @media (max-width: $custom-media) {
        padding-left: 15px;
      }
    }
  }
}

.page_content {
  .right {
    .order_consult {
      .title-big {
        padding-top: 20px;
      }
    }
  }
}

.page_content .right .order_consult .block-pd {
  margin-top: 4px;
  padding-top: 5px;
}

.page_content .right .order_consult form #consult-input-1 {
  background-image: url(../img/ics/user.png)
}

.page_content .right .order_consult form #consult-input-2 {
  background-image: url(../img/ics/small_phone.png)
}

.page_content .right .order_consult form #consult-input-3 {
  background-repeat: no-repeat;
  background-position: left 9px center;
  background-image: url(../img/ics/home.svg);
  -webkit-background-size: 16px;
  background-size: 16px;
}

.page_content .right .order_consult form div {
  position: relative;
}

.page_content .right .order_consult form > div input:focus ~ label {
  display: none !important
}

.page_content .right .order_consult form > div label {
  position: absolute;
  top: 9px;
  left: 35px;
  font-size: 16px;
  color: #000
}

.page_content .right .order_consult form > div label span {
  color: #E92C30
}

.page_content .right .order_consult form .radio_wrap {
  position: relative;
  margin-bottom: 20px
}

.page_content .right .order_consult form .radio_wrap.last {
  margin-bottom: 35px
}

.page_content .right .order_consult form .radio_wrap input {
  display: none
}

.page_content .right .order_consult form .radio_wrap input:checked ~ label:after {
  -webkit-transform: scale(1);
  transform: scale(1)
}

.page_content .right .order_consult form .radio_wrap label {
  position: static;
  padding-left: 30px;
  display: block;
  cursor: pointer
}

.page_content .right .order_consult form .radio_wrap label:before {
  display: block;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 1px;
  border: 2px solid #E92C30
}

.page_content .right .order_consult form .radio_wrap label:after {
  display: block;
  content: "";
  width: 14px;
  height: 14px;
  background-color: #E92C30;
  border-radius: 50%;
  left: 3px;
  top: 4px;
  position: absolute;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all .4s ease;
  transition: all .4s ease
}

.page_content .right .order_consult form input {
  display: block;
  width: 100%;
  font-size: 16px;
  height: 43px;
  background-color: #fff;
  margin-top: 7px;
  padding-left: 35px;
  padding-right: 22px;
  border: 2px solid #000;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  background-position: left 10px center;
  background-repeat: no-repeat
}

.page_content .right .order_consult form input:focus {
  border-color: #999
}

.page_content .right .order_consult form button {
  display: block;
  width: 100%;
  font-family: OpenSansBold;
  color: #fff;
  background-color: #E92C30;
  border: none;
  text-transform: uppercase;
  font-size: 18px;
  padding: 12px 0;
  margin-top: 7px
}

.page_content .right .order_consult form button:hover {
  background-color: #F45C70
}

.page_content .right .banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 40px 30px;
  border-bottom: 8px solid #F2F4F7
}

.page_content .right .banner .inner {
  width: 100%;
  max-width: 290px;
  margin: auto
}

.page_content .news .title {
  padding-right: 30px;
  padding-top: 30px
}

.page_content .news .select_cat {
  margin-bottom: 35px
}

.page_content .news .select_cat form {
  text-align: center;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  position: relative
}

.page_content .news .select_cat form:after {
  display: "block";
  content: "";
  width: 0;
  height: 0%;
  border-top: 9px solid #E92C30;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  position: absolute;
  top: 24px;
  right: 20px
}

.page_content .news .select_cat form select {
  display: inline-block;
  font-size: 16px;
  color: #000;
  border: 1px solid #E5E5E5;
  padding: 13px 20px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.page_content .news .items .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E5E5E5
}

.page_content {
  .news {
    .items {
      .item {
        .img {
          width: 23%;
          min-height: 170px;
          background-repeat: no-repeat;
          background-position: center center;
          -webkit-background-size: cover;
          background-size: cover;
          img {
            display: none;
          }
        }
      }
    }
  }
}

.page_content .news .items .item .text {
  width: 77%;
  padding-left: 50px;
  padding-right: 30px;
  color: #000;
  font-size: 16px;
  padding-top: 30px
}

.page_content .news .items .item .text h4,
.page_content .news .items .item .text .title {
  font-family: OpenSansBold
}

.page_content .news .items .item .text h4 a,
.page_content .news .items .item .text .title a {
  color: #000
}

.page_content .news .items .item .text .title a {
  font-size: 19px;
}

.page_content .news .items .item .text h4 a:hover,
.page_content .news .items .item .text .title a:hover {
  color: #E92C30;
  text-decoration: underline
}

.page_content .news .items .item .text p {
  margin-top: 20px
}

.page_content .news .items .item .text .item_text_bottom {
  margin-top: 70px
}

.page_content .news .items .item .text .item_text_bottom a,
.page_content .news .items .item .text .item_text_bottom span {
  display: inline-block
}

.page_content .news .items .item .text .item_text_bottom span {
  margin-right: 40px;
  color: #666;
  font-size: 14px
}

.page_content .news .items .item .text .item_text_bottom a {
  font-family: OpenSansBold;
  color: #000;
  position: relative
}

.page_content .news .items .item .text .item_text_bottom a:before {
  display: block;
  content: "";
  width: 6px;
  height: 3px;
  background-color: #F45C70;
  position: absolute;
  left: -24px;
  top: 11px
}

.page_content .news .items .item .text .item_text_bottom a:hover {
  text-decoration: underline;
  color: #F45C70
}

.page_content .reviews_form form .services_select > div a:focus,
.page_content .service_form form .services_select > div a:focus {
  text-decoration: none
}

.page_content .content_wrap .left .welcome_title .descr .bottom_text .mission:hover,
.page_content .content_wrap .right .clients .items > div:hover,
.page_content .content_wrap .right .last_projects .items .item:hover img {
  opacity: .7
}
