.schema_page {
  .title-big {
    margin-bottom: 0;
  }
  .schema {
    .block-pd {
      padding-bottom: 0;
    }
    .content {
      @media (max-width: $small-lap) {
        display: none;
      }
      .items {
        .text {
          padding-top: 0;
        }
        h4 {
          margin: 0;
        }
      }
    }
    .mobile-content {
      display: none;
      padding-left: 15px;
      @media (max-width: $small-lap) {
        display: block;
      }
      .mobile-schema {
        display: flex;
        flex-direction: column;
        align-items: center;
        counter-reset: section;
        .column {
          max-width: 335px;
          width: 100%;
          min-height: auto;
        }
        .schema-columns {
          display: none;
        }
        .schema-item {
          display: flex;
          max-width: 335px;
          width: 100%;
          position: relative;
          flex-wrap: wrap;
          margin-bottom: 45px;
          &:after {
            display: block;
            content: "";
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(../img/ics/arrow-down.svg);
            width: 25px;
            height: 25px;
            -webkit-background-size: 25px;
            background-size: 25px;
            position: absolute;
            bottom: -25px;
            left: 50%;
            transform: translateX(-50%);
          }
          .left {
            position: absolute;
            background-color: transparent;
            top: 0;
            left: 0;
            width: 136px;
            height: 142px;
            background-repeat: no-repeat;
            background-position: center center;
            -webkit-background-size: cover;
            background-size: cover;
          }
          .right {
            min-height: 142px;
            border: 1px solid $base-color;
            width: 100%;
            padding-left: 136px;
            display: flex;
            margin-top: 0;
            .text {
              margin: auto;
              font-size: 12px;
              line-height: 15px;
              font-weight: 600;
              text-align: center;
              text-transform: uppercase;
              h6, h4 {
                font-weight: 600;
                color: $base-color;
                font-size: 12px;
                margin: 0;
              }
            }
          }
          &.reviews, &.condition {
            background-color: $base-color;
            color: #fff;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            padding-top: 40px;
            padding-bottom: 40px;
            padding-right: 20px;
            padding-left: 96px;
            background-image: url(../img/ics/rev.png);
            background-repeat: no-repeat;
            background-position: left 35px center;
            &:after {
              display: none;
            }
          }
          &.condition {
            padding-left: 130px;
            background-position: left 70px center;
          }
          &.trigger {
            transition: all .3s ease;
            cursor: pointer;
            &:before {
              display: block;
              counter-increment: section;
              content: counter(section);
              text-align: center;
              font-weight: 600;
              font-size: 21px;
              width: 35px;
              height: 35px;
              line-height: 35px;
              color: #fff;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 10;
              background-color: $base-color;
            }
            &:hover {
              opacity: .7;
            }
            &:after {
              display: none;
            }
            &.active {
              &:after {
                display: block;
              }
              .details {
                display: none;
              }
            }
            .details {
              text-align: center;
              padding: 10px;
              background-color: $base-color;
              cursor: pointer;
              color: #fff;
              font-weight: 600;
              font-size: 12px;
              text-transform: uppercase;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.schema_page .schema .content {
  padding-top: 10px;
  padding-left: 38px;
  padding-bottom: 65px;
  position: relative
}

.schema_page .schema .content #blue_line {
  width: 881px;
  position: absolute;
  top: 359px;
  left: 15px;
  z-index: 100;
  height: 568px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../img/ics/blue_line.png);
  @media (max-width: $custom-media) {
    width: 729px;
    height: 506px;
    -webkit-background-size: 100%;
    background-size: 100%;
    top: 294px;
    left: 5px;
  }
  @media (max-width: $small-lap){
    display: none;
  }
}

.schema_page .schema .content > p {
  font-size: 14px;
  color: #000
}

.schema_page .schema .content .items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 40px 0
}

.schema_page .schema .content .items.first div:last-of-type:after {
  width: 191px;
  height: 93px;
  bottom: -93px;
  top: auto;
  right: 48%;
  background-color: transparent;
  border: 3px solid #E92C30;
  border-top: none;
  border-left: none;
  @media (max-width: $custom-media){
    height: 75px;
    bottom: -75px;
    right: 75px;
  }
  @media (max-width: $small-lap){
    display: none;
  }
}

.schema_page .schema .content .items.first div:last-of-type:before {
  top: 196px;
  left: -17px;
  right: 0;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  @media (max-width: $custom-media){
    top: 160px;
    left: -12px;
  }
  @media (max-width: $small-lap){
    display: none;
  }
}

.schema_page .schema .content .items.last > div .right {
  min-height: 142px
}

.schema_page .schema .content .items.last > div:first-of-type:after {
  display: block;
  content: "";
  width: 174px;
  height: 77px;
  bottom: -77px;
  top: auto;
  left: 175px;
  background-color: transparent;
  border: 3px solid #E92C30;
  border-top: none;
  border-right: none;
  position: absolute
}

.schema_page .schema .content .items.last > div:first-of-type:before {
  display: block;
  content: "";
  width: 11px;
  height: 11px;
  border: 3px solid #E92C30;
  position: absolute;
  right: -12px;
  top: auto;
  border-left: none;
  border-bottom: none;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  bottom: -81px
}

.schema_page .schema .content .items.last > div:last-of-type:after {
  display: block;
  content: "";
  width: 190px;
  height: 77px;
  bottom: -77px;
  top: auto;
  right: 160px;
  background-color: transparent;
  border: 3px solid #E92C30;
  border-top: none;
  border-left: none;
  position: absolute
}

.schema_page .schema .content .items.last > div:last-of-type:before {
  display: block;
  content: "";
  width: 11px;
  height: 11px;
  border: 3px solid #E92C30;
  position: absolute;
  left: -12px;
  top: auto;
  border-left: none;
  border-bottom: none;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  bottom: -81px
}

.schema_page .schema .content .items.last > div:nth-of-type(2):after {
  height: 30px;
  width: 3px;
  bottom: -30px;
  top: auto;
  right: 160px
}

.schema_page .schema .content .items.last > div:nth-of-type(2):before {
  bottom: -32px;
  top: auto;
  right: 156px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg)
}

.schema_page .schema .content .items.middle > div:after {
  height: 30px;
  width: 3px;
  bottom: -30px;
  top: auto;
  right: 160px
}

.schema_page .schema .content .items.middle > div:before {
  bottom: -32px;
  top: auto;
  right: 156px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg)
}

.schema_page .schema .content .items > div {
  width: 32%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative
}

.schema_page .schema .content .items > div:after {
  display: block;
  content: "";
  width: 15px;
  height: 3px;
  background-color: #E92C30;
  position: absolute;
  right: -15px;
  top: 53px
}

.schema_page .schema .content .items > div:before {
  display: block;
  content: "";
  width: 11px;
  height: 11px;
  border: 3px solid #E92C30;
  position: absolute;
  right: -14px;
  border-left: none;
  border-bottom: none;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 49px
}

.schema_page .schema .content .items > div .left {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0
}

.schema_page .schema .content .items > div .left:after {
  display: block;
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  left: 136px;
  top: 0;
  background-color: #E92C30
}

.schema_page .schema .content .items > div .left img {
  max-width: 136px
}

.schema_page .schema .content .items > div .right {
  text-align: center;
  width: 100%;
  padding-left: 136px;
  border: 1px solid #E92C30;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 110px
}

.schema_page .schema .content .items > div .right .text {
  margin: auto;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase
}

.schema_page .schema .content .items.last > div:first-of-type .right {
  &:after {
    display: block;
    content: "";
    width: 11px;
    height: 11px;
    position: absolute;
    right: -19px;
    bottom: 541px;
    border-right: 3px solid #E92C30;
    border-bottom: 3px solid #E92C30;
    transform: rotate(-45deg);
    @media (max-width: $custom-media) {
      bottom: 483px;
    }
    @media (max-width: $small-lap){
      display: none;
    }
  }
}

.schema_page .schema .content .items.last > div:first-of-type .text {
  position: relative;
  &:after {
    display: block;
    content: "";
    width: 15px;
    height: 450px;
    border-right: 3px solid #E92C30;
    border-bottom: 3px solid #E92C30;
    position: absolute;
    right: -10px;
    bottom: 50px;
    @media (max-width: $custom-media) {
      height: 410px;
    }
    @media (max-width: $small-lap){
      display: none;
    }
  }
  &:before {
    display: block;
    content: "";
    width: 11px;
    height: 11px;
    position: absolute;
    right: -18px;
    border-left: 3px solid #E92C30;
    border-top: 3px solid #E92C30;
    bottom: 496px;
    @media (max-width: $custom-media) {
      bottom: 450px;
    }
    @media (max-width: $small-lap){
      display: none;
    }
  }
}

.schema_page .schema .content .items > div .right .text h4,
.schema_page .schema .content .items > div .right .text h6 {
  color: #E92C30;
  font-family: OpenSansBold;
  margin-bottom: 7px;
  font-size: 12px
}

.schema_page .schema .content .items > div .right .text h6 {
  margin: 0
}

.schema_page .schema .content .items > div .right .text span {
  color: #000;
  font-family: OpenSansBold
}

.schema_page .schema .step {
  width: 32%;
  margin-left: auto;
  margin-right: auto;
  background-color: #E92C30;
  padding-left: 150px;
  padding-top: 35px;
  padding-bottom: 35px;
  background-repeat: no-repeat;
  background-position: left 100px center;
  background-image: url(../img/ics/doc.png);
  position: relative
}

.schema_page .schema .step.first {
  h4 {
    position: relative;
  }
  .arrow {
    display: inline-block;
    width: 3px;
    height: 35px;
    background-color: #E92C30;
    position: absolute;
    bottom: -25px;
    left: 51.5%;
    z-index: 10;
    @media (max-width: $small-lap){
      left: 55.5%;
    }
    &:after {
      display: block;
      content: "";
      width: 11px;
      height: 11px;
      border-bottom: 3px solid #E92C30;
      border-right: 3px solid #E92C30;
      transform: rotate(45deg);
      position: absolute;
      bottom: -2px;
      left: -4px;
    }
  }
}

.schema_page .schema .step.first h4:after {
  display: block;
  content: "";
  width: 184px;
  height: 65px;
  bottom: -65px;
  top: auto;
  left: -335px;
  background-color: transparent;
  border: 3px solid #E92C30;
  border-bottom: none;
  border-right: none;
  position: absolute;
  @media (max-width: $custom-media){
    bottom: -58px;
    left: -262px;
  }
  @media (max-width: $small-lap){
    display: none;
  }
}

.schema_page .schema .step.first h4:before,
.schema_page .schema .step.first:after {
  border: 3px solid #E92C30;
  content: "";
  position: absolute;
  display: block;
}

.schema_page .schema .step.first h4:before {
  width: 11px;
  height: 11px;
  left: -339px;
  border-left: none;
  border-bottom: none;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 90px;
  @media (max-width: $custom-media){
    top: 81px;
    left: -266px;
  }
  @media (max-width: $small-lap){
    display: none;
  }
}

.schema_page .schema .step.first:after {
  width: 200px;
  height: 65px;
  bottom: -31px;
  top: auto;
  right: -196px;
  background-color: transparent;
  border-bottom: none;
  border-left: none;
  @media (max-width: $custom-media){
    bottom: -26px;
  }
  @media (max-width: $small-lap){
    display: none;
  }
}

.schema_page .schema .step.first:before {
  display: block;
  content: "";
  width: 11px;
  height: 11px;
  border: 3px solid #E92C30;
  position: absolute;
  right: -200px;
  border-left: none;
  border-bottom: none;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 148px;
  @media (max-width: $custom-media){
    top: 115px;
  }
  @media (max-width: $small-lap){
    display: none;
  }
}

.schema_page .schema .step.last {
  background-image: url(../img/ics/rev.png);
  background-position: left 90px center
}

.schema_page .schema .step h4 {
  text-transform: uppercase;
  color: #fff;
  line-height: 17px;
  font-size: 12px;
  font-weight: 600;;
}
