.news_page .left .subscribe_form {
  background-color: #24262B;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px
}

.news_page .left .subscribe_form>div {
  position: relative
}

.news_page .left .subscribe_form>div label {
  display: block;
  color: #CCC;
  font-size: 18px;
  padding-left: 15px;
  padding-bottom: 20px;
  padding-top: 20px
}

.news_page .left .subscribe_form>div input {
  width: 100%;
  background-color: #fff;
  height: 44px;
  border: none;
  font-size: 18px;
  padding: 0 15px
}

.news_page .left .subscribe_form>div button {
  position: absolute;
  width: 130px;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  height: 44px;
  right: 0;
  bottom: 0;
  background-color: #E92C30;
  border: none
}

.news_page .left .subscribe_form>div button:hover {
  background-color: #F45C70
}