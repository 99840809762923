@mixin font-face($font-family, $file-path, $font-weight, $font-style){
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
    url('#{$file-path}.woff') format('woff'),
    url('#{$file-path}.ttf') format('truetype'),
    url('#{$file-path}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@include font-face("OpenSans", "../fonts/OpenSans/OpenSans", 400, normal);
@include font-face("OpenSans", "../fonts/OpenSans/OpenSans-Bold", 600, normal);
@include font-face("OpenSans", "../fonts/OpenSans/OpenSans-Italic", 400, italic);

@include font-face("Exo2", "../fonts/Exo2/Exo2-0-Regular", 400, normal);
@include font-face("Exo2", "../fonts/Exo2/Exo2-Light", 300, normal);

@include font-face("Roboto", "../fonts/Roboto/Roboto-Regular", 400, normal);
@include font-face("Roboto", "../fonts/Roboto/Roboto-Bold", 600, normal);



