.service_page .content_wrap .left .direction .items {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.service_page .content_wrap .left .direction .items>div {
  border-right: 5px solid #fff;
  width: 33.33%;
  border-bottom: none;
  border-top: 5px solid #fff
}

.service_page .content_wrap .left .direction .items>div:hover img {
  -webkit-filter: brightness(1);
  filter: brightness(1)
}

.service_page .content_wrap .left .direction .items>div:hover .overlay {
  -webkit-transform: scale(.8);
  transform: scale(.8);
  opacity: 0
}

.service_page .content_wrap .left .direction .items>div .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, .55);
  -webkit-transition: all .9s ease;
  transition: all .9s ease
}

.service_page .content_wrap .left .direction .items>div .text h4 {
  font-size: 18px!important
}

.service_page .content_wrap .left .direction .items>div:nth-child(3n) {
  border-right: none
}

.service_page .content_wrap .right .order_consult {
  border-top: 8px solid #F2F4F7;
  margin-bottom: -8px;
}