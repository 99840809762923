@import "../pages/layout/layout";

@import "../pages/map-page";
@import "../pages/news-page";
@import "../pages/reviews-page";
@import "../pages/service-page";
@import "../pages/typical-page";
@import "../pages/price-page";
@import "../pages/portfolio-page";
@import "../pages/schema-page";
