header {
  position: absolute!important;
  width: 100%;
  top: 25px!important;
  left: 0;
  z-index: 500;
  transition: all .4s ease;
  padding-left: 8px
}

header .top {
  padding-right: 15px;
  padding-left: 141px
}

header .top .logo {
  position: relative;
  width: 24%
}

header .top .logo:hover {
  opacity: .7
}

header .top .logo a {
  x-position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

header .top .nav_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 35px 38px;
  background-color: #fff;
  position: relative;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 16px;
  color: #000;
  transition: all .4s ease;
  box-shadow: none!important;
}

header .top .nav_wrap #menu_btn {
  font-size: 16px;
  color: #fff;
  position: absolute;
  left: -130px;
  background-color: #111;
  height: 100%;
  top: 0;
  z-index: 300;
  width: 115px;
  text-align: center;
  padding-top: 108px;
  cursor: pointer;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  background-image: url(../img/ics/menu_bars.png);
  .mob-icon {
    display: none;
  }
}

header .top .nav_wrap #menu_btn:hover {
  background-color: #F45C70
}

header .top .work_shedule {
  width: 11.3%
}

header .top .work_shedule p {
  padding-left: 15px;
  margin-bottom: 20px
}

header .top .work_shedule>div {
  padding-left: 15px;
  padding-right: 15px;
  border-left: 2px solid #F45C70;
  border-right: 2px solid #F45C70;
  padding-top: 10px
}

header .top .work_shedule>div span {
  display: block
}

header .top .nav_info {
  width: 27%
}

header .top .nav_info ul {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px
}

header .top .nav_info ul li {
  display: inline-block;
  margin-right: 75px
}

header .top .nav_info ul li:last-child {
  margin-right: 0
}

header .top .nav_info ul li:first-child {
  color: #666
}

header .top .nav_info ul li a {
  color: #000
}

header .top .nav_info ul li a:hover {
  text-decoration: underline;
  color: #F45C70
}

header .top .nav_info p {
  color: #666
}

header .top .contact_numbers_and_button {
  width: 25.5%;
  text-align: center
}

header .top .contact_numbers_and_button>div {
  margin-bottom: 25px
}

header .top .contact_numbers_and_button>div span {
  display: inline-block;
  font-size: 16px
}

header .top .contact_numbers_and_button>div span span {
  font-family: OpenSans
}

header .top .contact_numbers_and_button .order_consult {
  display: inline-block;
  background-color: #E92C30;
  color: #fff;
  font-size: 18px;
  font-family: OpenSansBold;
  text-transform: uppercase;
  width: 100%;
  padding: 15px 0;
  -webkit-transition: all .5s linear;
  transition: all .5s linear
}

header .top .contact_numbers_and_button .order_consult:focus {
  outline: 0;
  text-decoration: none
}

header .top .contact_numbers_and_button .order_consult:hover {
  background-color: #F45C70
}

header .top .language {
  width: 3%;
  text-align: center;
  padding-top: 20px
}

header .top .language a,
header .top .language span {
  display: block;
  padding: 1px 5px;
  margin-bottom: 5px;
  font-family: OpenSansBold;
  color: #CCC;
  margin-left: auto;
  width: 50px;
  border: 2px solid transparent;
  -webkit-transition: all .4s ease;
  transition: all .4s ease
}

header .top .language a.active,
header .top .language span.active {
  color: #000;
  border-color: #F45C70
}

header .top .language a:hover,
header .top .language span:hover {
  border-color: #CCC
}

header .top .contact_numbers_and_button>div span,
header .top .nav_info ul li a,
header .top .nav_wrap #menu_btn {
  font-family: "OpenSans";
  font-weight: 600;
}

header .top .nav_wrap #menu_btn {
  background-position: center center;
  background-repeat: no-repeat;
}