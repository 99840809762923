#map {
  min-height: 670px
}

.map_page .contacts_info .text .back_to_contacts:hover,
.map_page .message_form form button:hover {
  opacity: .7
}

.map_page .title {
  padding-top: 30px;
  padding-right: 30px
}
.page_content {
  .map_page {
    .block-pd {
      padding-bottom: 0;
    }
    .contacts_info {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding-bottom: 20px;
      .text {
        width: 58%;
        padding-left: 25px;
        padding-right: 30px;
        color: #000;
        font-size: 16px;
        @media (max-width: $custom-media) {
          font-size: 12px;
          padding-left: 15px;
          padding-right: 15px;
        }
        h4 {
          @media (max-width: $custom-media){
            margin-top: 0;
            margin-bottom: 8px;
            font-size: 14px;
          }
        }
        p {
          @media (max-width: $custom-media){
            margin-bottom: 0;
          }
        }
      }
    }
  }
}



.map_page .contacts_info .img {
  width: 42%;
  img {
    width: 100%!important;
    height: auto!important;
  }
}

.map_page .contacts_info .text h4 {
  font-family: OpenSansBold;
}

.map_page .contacts_info .text p:first-of-type {
  margin: 15px 0;
  @media (max-width: $custom-media){
    margin-bottom: 0;
    margin-top: 8px;
  }
}

.map_page .contacts_info .text p:nth-of-type(3) {
  margin-bottom: 15px;
  @media (max-width: $custom-media){
    margin-bottom: 0;
  }
}

.map_page .contacts_info .text p a,
.map_page .contacts_info .text p span {
  font-family: OpenSansBold;
}

.map_page .contacts_info .text p a {
  color: #000
}

.map_page .contacts_info .text p a:hover {
  color: #F45C70
}

.map_page .contacts_info .text p:last-of-type {
  margin-top: 15px;
  @media (max-width: $custom-media){
    margin-top: 0;
  }
}

.map_page .contacts_info .text .back_to_contacts {
  background-color: #E92C30;
  font-family: OpenSansBold;
  color: #fff;
  padding: 8px 35px;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 20px;
}

.map_page .message_form {
  background-color: #F2F4F7;
  padding-top: 8px
}

.map_page .message_form h4 {
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #24262B;
  margin: 0;
}

.map_page .message_form form {
  padding: 0 30px 20px;
  background-color: #24262B
}

.map_page .message_form form .top>div {
  position: relative
}

.map_page .message_form form .top>div label {
  position: absolute;
  color: #000;
  font-size: 16px;
  left: 45px;
  top: 12px
}

.map_page .message_form form .top>div label span {
  color: #E92C30
}

.map_page .message_form form .top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.map_page .message_form form .top>div:first-of-type input {
  background-image: url(../img/ics/user.png)
}

.map_page .message_form form .top>div:nth-of-type(2) input {
  background-image: url(../img/ics/small_phone.png)
}

.map_page .message_form form .top>div:not(:last-of-type) {
  width: 30%
}

.map_page .message_form form .top>div:last-of-type {
  width: 37%
}

.map_page .message_form form .top>div:last-of-type input {
  background-image: url(../img/ics/envelope.png)
}

.map_page .message_form form .top>div input {
  width: 100%;
  display: inline-block;
  border: none;
  height: 50px;
  padding-left: 45px;
  padding-right: 20px;
  font-size: 16px;
  color: #000;
  background-repeat: no-repeat;
  background-position: left 20px center
}

.map_page .message_form form .top>div input:focus~label {
  display: none
}

.map_page .message_form form textarea {
  display: inline-block;
  margin-top: 15px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  font-size: 16px;
  color: #000;
  min-height: 155px;
  max-height: 155px;
  min-width: 100%;
  max-width: 100%
}

.map_page .message_form form textarea::-webkit-input-placeholder {
  color: #000
}

.map_page .message_form form button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-family: OpenSansBold;
  text-transform: uppercase;
  font-size: 18px;
  background-color: #E92C30;
  border: none;
  padding: 10px 0;
  width: 400px;
  color: #fff;
  margin-top: 9px
}