footer {
  padding-bottom: 80px;
  margin-top: 20px
}

footer .items_wrap {
  padding: 45px 20px 35px;
  background-color: #24262B;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #fff
}

footer .items_wrap .first {
  width: 25%
}

footer .items_wrap .first p {
  font-size: 16px;
  line-height: 19px
}

footer .items_wrap .first .count_and_soc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 20px
}

footer .items_wrap .first .count_and_soc>div:first-child a {
  display: inline-block;
  width: 88px;
  height: 31px;
  background-position: center center;
  background-repeat: no-repeat;
}

footer .items_wrap .first .count_and_soc>div:first-child a:first-child {
  background-image: url(../img/ics/count.png);
  margin-right: 8px
}

footer .items_wrap .first .count_and_soc>div:first-child a:last-child {
  background-image: url(../img/ics/bigmir.png)
}

footer .items_wrap .first .count_and_soc>div:last-child a {
  display: inline-block;
  width: 28px;
  height: 28px;
  margin: 0 3px;
  background-position: center center;
  background-repeat: no-repeat;
}

footer .items_wrap .first .count_and_soc>div:last-child a:hover {
  opacity: .5
}

footer .items_wrap .first .count_and_soc>div:last-child a.plus {
  background-image: url(../img/ics/socials/plus.png)
}

footer .items_wrap .first .count_and_soc>div:last-child a.facebook {
  background-image: url(../img/ics/socials/facebook.png)
}

footer .items_wrap .first .count_and_soc>div:last-child a.twitter {
  background-image: url(../img/ics/socials/twitter.png)
}

footer .items_wrap .first .count_and_soc>div:last-child a.play {
  background-image: url(../img/ics/socials/play.png)
}

footer .items_wrap .work_shedule {
  width: 12%
}

footer .items_wrap .work_shedule p {
  padding-left: 15px;
  margin-bottom: 20px;
  color: #999
}

footer .items_wrap .work_shedule>div {
  padding-left: 15px;
  padding-right: 15px;
  border-left: 2px solid #F45C70;
  border-right: 2px solid #F45C70;
  padding-top: 10px
}

footer .items_wrap .work_shedule>div span {
  display: block
}

footer .items_wrap .nav_info {
  width: 31%
}

footer .items_wrap .nav_info ul {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px
}

footer .items_wrap .nav_info ul li {
  display: inline-block;
  margin-right: 55px;
  font-size: 16px
}

footer .items_wrap .nav_info ul li:last-child {
  margin-right: 0
}

footer .items_wrap .nav_info ul li:first-child {
  color: #999
}

footer .items_wrap .nav_info ul li a {
  font-family: OpenSansBold;
  color: #fff;
  font-size: 16px
}

footer .items_wrap .nav_info ul li a:hover {
  text-decoration: underline;
  color: #F45C70
}

footer .items_wrap .nav_info p {
  color: #999;
  font-size: 16px
}

footer .items_wrap .contact_numbers_and_button {
  width: 26%;
  text-align: center
}

footer .items_wrap .contact_numbers_and_button>div {
  margin-bottom: 25px
}

footer .items_wrap .contact_numbers_and_button>div span {
  display: inline-block;
  font-size: 14px
}

footer .items_wrap .contact_numbers_and_button>div span span {
  font-family: OpenSansBold
}

footer .items_wrap .contact_numbers_and_button .order_consult {
  display: inline-block;
  background-color: #E92C30;
  color: #fff;
  font-size: 18px;
  font-family: OpenSansBold;
  text-transform: uppercase;
  width: 100%;
  padding: 15px 0;
  -webkit-transition: all .5s linear;
  transition: all .5s linear
}

footer .items_wrap .contact_numbers_and_button .order_consult:focus {
  outline: 0;
  text-decoration: none
}

footer .items_wrap .contact_numbers_and_button .order_consult:hover {
  background-color: #F45C70
}

footer .items_wrap .first .count_and_soc>div:first-child a:hover {
  opacity: .7
}