/* Popup with form styles */

.modal_overlay {
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: 9000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.modal_overlay.closed {
  visibility: hidden;
  opacity: 0
}

.modal_overlay.opened {
  visibility: visible;
  opacity: 1
}

#hidden-form {
  padding: 0;
  width: 100%;
  max-width: 760px;
  z-index: 9999;
  margin: auto;
  position: relative
}

#hidden-form #close_popup {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  background-image: url(../img/ics/close_white.png);
  background-position: center center;
  background-repeat: no-repeat;
}

#hidden-form #close_popup:hover {
  background-color: #5E6471
}

#hidden-form h2,
#hidden-form .title {
  text-transform: uppercase;
  background-color: #383A3F;
  color: #fff;
  font-size: 28px;
  text-align: center;
  padding: 30px 0
}

#hidden-form form {
  padding: 30px 55px;
  color: #000;
  background-color: #F2F4F7;
  .address-input-wrap {
    input {
      background-repeat: no-repeat;
      background-position: left 14px center;
      background-image: url(../img/ics/home.svg);
      -webkit-background-size: 16px;
      background-size: 16px;
    }
  }
}

#hidden-form form button {
  font-family: "OpenSans";
  font-weight: 600;
}

#hidden-form form .flex_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

#hidden-form form .flex_wrap .img {
  width: 40%
}

#hidden-form form .flex_wrap .inputs {
  width: 60%;
  padding-left: 35px
}

#hidden-form form #modal_name {
  background-position: left 15px center;
  background-image: url(../img/ics/user.png);
}

#hidden-form form #modal_phone {
  background-position: left 15px center;
  background-image: url(../img/ics/small_phone.png)
}

#hidden-form form #modal_email {
  background-position: left 15px center;
  background-image: url(../img/ics/envelope.png)
}

#hidden-form form>div>div>div {
  position: relative
}

#hidden-form form>div label {
  display: block;
  font-size: 16px;
  position: absolute;
  top: 11px;
  left: 42px
}

#hidden-form form>div label span {
  color: #E92C30
}

#hidden-form form>div input {
  display: inline-block;
  width: 100%;
  background-color: #fff;
  border: 2px solid #000;
  height: 45px;
  font-size: 16px;
  padding-left: 40px;
  padding-right: 20px;
  color: #000;
  margin-bottom: 18px;
  background-repeat: no-repeat
}

#hidden-form form>div input:focus~label {
  display: none
}

#hidden-form form button {
  width: 100%;
  background-color: #E92C30;
  border: none;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  padding: 11px 0;
  margin-top: 15px
}

#hidden-form form button:hover {
  opacity: .7
}



/* Scroll top button styles */

#scroll_top_btn:after,
#scroll_top_btn:before {
  display: block;
  width: 20px;
  height: 20px;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  position: absolute;
  left: 10px;
  top: 16px;
  content: ""
}

#scroll_top_btn {
  position: fixed;
  z-index: 400;
  left: -45px;
  bottom: 25px;
  width: 40px;
  height: 40px;
  background-color: #E92C30;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

#scroll_top_btn:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all .4s ease;
  transition: all .4s ease
}

#scroll_top_btn:before,
header {
  -webkit-transition: all .4s ease
}

#scroll_top_btn:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all .4s ease;
  opacity: 0
}

#scroll_top_btn:hover:after {
  -webkit-transform: scale(3.8) rotate(-45deg);
  transform: scale(3.8) rotate(-45deg);
  opacity: 0;
  border-color: #000
}

#scroll_top_btn:hover:before {
  opacity: 1
}



/* Main slider styles */

#site_carousel,
#site_carousel .item .container {
  position: relative
}

#site_carousel .owl-item.active .item .text {
  opacity: 1
}

#site_carousel .item img {
  z-index: 200
}

#site_carousel .item .text {
  width: 550px;
  background-color: rgba(36, 38, 43, .92);
  z-index: 1000;
  position: absolute;
  top: 260px;
  left: 8px;
  padding: 20px 30px;
  color: #fff;
  opacity: 0;
  -webkit-transition: all .4s ease;
  transition: all .4s ease
}

#site_carousel .item .text h4 {
  font-family: Exo2Light;
  font-size: 36px;
  line-height: 45px;
  text-transform: uppercase
}

#site_carousel .item .text p {
  font-size: 16px;
  margin-top: 30px
}

#site_carousel .owl-controls .owl-nav {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 40%;
  height: 77px
}

#site_carousel .owl-controls .owl-nav>div {
  display: inline-block;
  width: 41px;
  height: 77px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  position: absolute;
  top: 0;
  background-position: center center;
  background-repeat: no-repeat;
}

#site_carousel .owl-controls .owl-nav>div.owl-prev {
  background-image: url(../img/ics/prev.png);
  left: 40px
}

#site_carousel .owl-controls .owl-nav>div.owl-prev:hover {
  background-image: url(../img/ics/prev_active.png)
}

#site_carousel .owl-controls .owl-nav>div.owl-next {
  background-image: url(../img/ics/next.png);
  right: 40px
}

#site_carousel .owl-controls .owl-nav>div.owl-next:hover {
  background-image: url(../img/ics/next_active.png)
}

#site_carousel .owl-controls .owl-dots {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 45px
}

#site_carousel .owl-controls .owl-dots .owl-dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid #fff;
  margin: 0 15px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease
}

#site_carousel .owl-controls .owl-dots .owl-dot:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3)
}

#site_carousel .owl-controls .owl-dots .owl-dot.active {
  border-color: #E92C30
}



/* Breadcrumbs styles */
#breadcrumbs {
  padding-top: 15px;
  padding-bottom: 15px
}

#breadcrumbs .links {
  color: #000
}

#breadcrumbs .links>:nth-child(1) {
  font-family: OpenSans
}

#breadcrumbs .links>:nth-child(1):after,
#breadcrumbs .links>:nth-child(1):before {
  display: none
}

#breadcrumbs .links a:hover {
  color: #F45C70
}

#breadcrumbs .links a {
  font-family: OpenSans!important
}

#breadcrumbs .links a,
#breadcrumbs .links span {
  font-family: OpenSansBold;
  text-transform: uppercase;
  display: inline-block;
  color: #000;
  margin-right: 45px;
  position: relative
}

#breadcrumbs .links a:before,
#breadcrumbs .links span:before {
  display: block;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #E92C30;
  position: absolute;
  left: -33px;
  top: 2px
}

#breadcrumbs .links a:after,
#breadcrumbs .links span:after {
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 8px;
  left: -29px
}

#breadcrumbs .links span:last-of-type {
  margin-right: 0
}



/* Service directions styles */

.direction .items>div .text {
  -webkit-transition: all .8s ease;
  transition: all .8s ease
}

.direction .items>div .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, .55);
  -webkit-transition: all .4s ease;
  transition: all .4s ease
}

.direction .items>div .overlay.o-1 {
  left: 0
}

.direction .items>div .overlay.o-2 {
  right: 0
}
//.direction .items>div:hover .o-1 {
//  left: -50%
//}
//
//.direction .items>div:hover .o-2 {
//  right: -50%
//}

.direction .items>div:hover .text {
  -webkit-transform: scale(.7);
  transform: scale(.7)
}



/* Pagination styles */
.pagination {
  text-align: center;
  padding: 35px 0
}

.pagination .links .prev {
  margin-right: 27px
}

.pagination .links .next {
  margin-left: 27px
}

.pagination .links a,
.pagination .links span {
  text-transform: uppercase;
  font-size: 16px;
  color: #000;
  padding: 7px 10px
}

.pagination .links .current,
.pagination .links a:hover,
.pagination .links span:hover {
  background-color: #F45C70;
  color: #fff
}



/* Gallery styles */
/* @todo */ //must find html for this styles
.gallery>div,
.gallery>div img {
  min-height: 190px
}

.gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-top: 5px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 8px solid #F2F4F7
}

.gallery>div {
  width: 33.33%;
  border-right: 5px solid #fff;
  overflow: hidden;
  position: relative;
  max-height: 190px;
  border-bottom: 5px solid #fff;
  cursor: pointer
}

.gallery>div:hover .overlay {
  opacity: 1
}

.gallery>div {
  a {
    display: block;
    min-height: 185px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .4);
    opacity: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(../img/ics/search.png);
  }
}

.gallery>div:nth-child(3n) {
  border-right: none
}