.reviews_page .title {
  padding-right: 30px;
  padding-top: 30px
}

.reviews_page .title h3 {
  margin-bottom: 10px
}

.reviews_page .items .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 30px;
  padding-top: 20px;
  border-bottom: 1px solid #EBEBEB
}

.reviews_page .items .item .text {
  padding-top: 30px;
  padding-right: 30px
}

.reviews_page .items .item .img {
  width: 23%
}

.reviews_page .items .item .text {
  width: 77%;
  padding-left: 45px;
  color: #000;
  font-size: 14px
}

.reviews_page .items .item .text h4,
.reviews_page .items .item .text .name {
  font-family: OpenSansBold
}

.reviews_page .items .item .text p:first-of-type {
  color: #999;
  margin-bottom: 25px
}

.reviews_page .items .item .text .review_text {
  max-height: 50px;
  overflow: hidden
}

.reviews_page .items .item .text a {
  text-transform: uppercase;
  font-family: OpenSansBold;
  display: inline-block;
  margin-top: 20px;
  border-bottom: 2px solid transparent;
  -webkit-transition: all .4s ease;
  transition: all .4s ease
}

.reviews_page .items .item .text a:focus {
  text-decoration: none
}

.reviews_page .items .item .text a.open {
  color: #666
}

.reviews_page .items .item .text a.open:hover {
  border-color: #666
}

.reviews_page .items .item .text a.close {
  color: #E92C30
}

.reviews_page .items .item .text a.close:hover {
  border-color: #E92C30
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .top .left>div,
.page_content.reviews_page_content .content_wrap .left .reviews_form .top .right_block>div {
  margin-bottom: 15px;
  position: relative
}

.page_content.reviews_page_content .content_wrap .left .reviews_form {
  border-top: 8px solid #F2F4F7
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .top .left {
  width: 43%;
  background-color: transparent;
  padding-right: 15px
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .top .left>div:first-of-type input {
  background-image: url(../img/ics/user.png)
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .top .left>div:nth-of-type(2) input {
  background-image: url(../img/ics/small_phone.png)
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .top .left>div:nth-of-type(3) input {
  background-image: url(../img/ics/envelope.png)
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .top .right_block {
  width: 57%;
  background-color: transparent
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .top .right_block>div input {
  background-image: none;
  padding-left: 20px
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .top .right_block>div label span {
  color: #999
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .top .right_block .adress label span {
  color: #E92C30
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .what_realised {
  position: relative;
  margin-top: 25px
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .what_realised input {
  width: 100%;
  display: inline-block;
  border: none;
  height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  color: #000;
  background-repeat: no-repeat;
  background-position: left 20px center
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .what_realised input:focus~label {
  display: none
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .what_realised label {
  position: absolute;
  color: #000;
  font-size: 16px;
  left: 20px;
  top: 12px
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .what_realised label span {
  color: #E92C30
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .message_area {
  margin-top: 15px
}

.page_content.reviews_page_content .content_wrap .left .reviews_form .message_area textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: 100px;
  padding-left: 20px;
  padding-top: 12px;
  font-size: 16px;
  border: none
}